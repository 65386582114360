import { useEffect, useState } from "react"
import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
} from "@mui/material"
import { isAnyBlank } from "../../utils/string"
import css from "./ModalEditarEmailAccesoClassfy.module.css"

const ModalEditarEmailAccesoClassfy = ({ open, accesoCliente, onClose, onSubmit }) => {

  const [email, setEmail] = useState("")
  const [emailVerificado, setEmailVerificado] = useState(false)

  useEffect(() => {
    if (!accesoCliente) return
    setEmail(accesoCliente.email)
    setEmailVerificado(accesoCliente.emailVerificado)
  }, [open, accesoCliente])

  const isSubmitDisabled = isAnyBlank(email)
  
  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth="sm">
      <DialogTitle>Editar email de acceso a Classfy</DialogTitle>
      <DialogContent>
        <Alert
          icon={<i className="material-icons">warning</i>}
          className={css.alert}
          severity="warning"
        >
          Si se modifica el email del alumno se le cerrarán todas las sesiones y tendrá que volver
          a iniciar sesión utilizando el nuevo email.
          <br />
          Si se quita la verificación de email, el alumno no podrá iniciar sesión hasta que vuelva a 
          verificar su email.
        </Alert>
        <div className={css.info}>
          <TextField
            label="Email"
            value={email} 
            variant='standard'
            onChange={(event)=> setEmail(event.target.value)}
          />
          <FormControlLabel
            control={(
              <Checkbox 
                checked={emailVerificado}
                onChange={(event)=> setEmailVerificado(event.target.checked)}
              />
            )} 
            label='Email verificado'
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={onClose}>
          Cancelar
        </Button>
        <Button 
          color="primary" 
          onClick={() => onSubmit({ email, emailVerificado })} 
          disabled={isSubmitDisabled}>
          Actualizar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalEditarEmailAccesoClassfy
