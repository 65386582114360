import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Typography,
} from "@mui/material"
import css from "./ConfigurarAcceso.module.css"

const ConfigurarAcceso = () => {

  return (
    <Accordion expanded>
      <AccordionSummary>
        <div className={css.label}>
          <Typography className={css.title} variant="h2">
            Sin acceso a Classfy
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Alert 
          icon={<i className='material-icons'>warning</i>} 
          className={css.alert} 
          severity='warning'
        >
          Este alumno no tiene configurado acceso a Classfy.
        </Alert>
      </AccordionDetails>
    </Accordion>
  )
}

export default ConfigurarAcceso
