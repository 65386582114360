import { useState } from 'react'
import { getAlumnoListYear } from '../../../utils/api/alumnos'
import { IconButton, Link } from '@mui/material'
import InformationTable from '../../../components/Table/InformationTable'
import { useQuery } from '@tanstack/react-query'
import { useNotification } from '../../../context/NotificationManager/NotificationManager'
import { downloadCSVFile, getCSVContent } from '../../../utils/csv'
import TableHeader from '../../../components/TableHeader/TableHeader'
import PageLoading from '../../../components/PageLoading/PageLoading'
import { textFilter } from '../../../utils/table'
import EjercicioSelector from '../../../components/form/EjercicioSelector/EjercicioSelector'
import AcademiaSelector from '../../../components/form/AcademiaSelector/AcademiaSelector'
import css from './Alumnos.module.css'

const Alumnos = ()=> {

  const notification = useNotification()

  const [search, setSearch] = useState('')
  const [centro, setCentro] = useState('')
  const [ejercicio, setEjercicio] = useState(new Date().getFullYear())

  const { isLoading, data: alumnoList=[] } = useQuery({
    queryKey: ['alumnos', 'year', ejercicio, centro],
    enabled: !!ejercicio && !!centro, 
    queryFn: ()=> getAlumnoListYear({ periodo: ejercicio, centro })
    .then(datos=> datos)
    .catch(err=> {
      notification.error({ title: 'Error al recuperar los alumnos', content: err })
      return []
    })
  })

  const handleExport = ()=> {
    const content = getCSVContent({
      columns: ['Nombre', 'Teléfono Fijo', 'Teléfono Móvil', 'E-mail'],
      data: alumnoList,
      transform: c=> ([
        c.nombre,
        c.fijo,
        c.movil,
        c.email,
      ])
    })
    downloadCSVFile(content, `alumnos-${centro}-${ejercicio}`)
  }
  
  const filterSearch = alumno=> {
    return textFilter({ 
      object: alumno, 
      fields: ['nombre', 'fijo', 'movil', 'email'],
      search: search
    })
  }

  return (
    <PageLoading isLoading={isLoading}>
      <TableHeader
        actions={(
          <div className={css.actions}>
            <IconButton 
              className={css.download} 
              disabled={!alumnoList.length}
              size='small' 
              color='primary' 
              onClick={handleExport}
            >
              <i className='material-icons'>download</i>
            </IconButton>
            <EjercicioSelector
              className={css.ejercicio}
              name='ejercicio'
              label='Año'
              value={ejercicio}
              onChange={e=> setEjercicio(e.target.value)}
            />
            <AcademiaSelector
              className={css.academia}
              name='academia'
              label='Academia'
              value={centro}
              onChange={e=> setCentro(e.target.value)}
            />
          </div>
        )}
        title={`${alumnoList.length} alumnos de ${ejercicio}${centro ? ` en ${centro}` : ''}`}
        search={search}
        onSearchChange={e=> setSearch(e.target.value)}
      />
      <InformationTable
        details={[
          { title: 'Nombre', key: 'nombre' },
          { title: 'Fijo', key: 'fijo' },
          { title: 'Móvil', key: 'movil' },
          { title: 'E-mail', key: 'email' }
        ]}
        data={alumnoList.filter(filterSearch).map((alumno) => ({
          nombre: (
            <Link
              className={css.link}
              href={`/alumnos/${alumno.id}/ficha`}
            >
              {alumno.nombre}
            </Link>
          ),
          fijo: alumno.fijo || '--',
          movil: alumno.movil || '--',
          email: alumno.email || '--',
        }))}
        isFetching={isLoading}
      />
    </PageLoading>
  )
}

export default Alumnos
