import { IconButton } from '@mui/material'
import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import PageLoading from '../../components/PageLoading/PageLoading'
import InformationTable from '../../components/Table/InformationTable'
import TableHeader from '../../components/TableHeader/TableHeader'
import { useNotification } from '../../context/NotificationManager/NotificationManager'
import { getUsuarioList } from '../../utils/api/usuarios'
import { downloadCSVFile, getCSVContent } from '../../utils/csv'
import { textFilter } from '../../utils/table'
import { dateToString } from '../../utils/date'

const Usuarios = ()=> {

  const notification = useNotification()

  const [search, setSearch] = useState('')

  const { isLoading, data: adminList=[] } = useQuery({
    queryKey: ['usuario', 'list'], 
    queryFn: ()=> getUsuarioList()
      .then(datos=> datos.map(usuario=> ({
        ...usuario,
        ultimaConexion: dateToString(usuario.ultimaConexion) || '--'
      })))
      .catch(err=> {
        notification.error({ title: 'Error al recuperar los usuarios', content: err })
        return []
      })
  })

  const handleExport = ()=> {
    const content = getCSVContent({
      columns: ['Nombre', 'Email', 'Rol', 'Última conexión'],
      data: adminList,
      transform: a=> ([
        a.nombre,
        a.email,
        a.rol,
        a.ultimaConexion
      ])
    })
    downloadCSVFile(content, 'usuarios')
  }

  const filterSearch = usuario=> {
    return textFilter({ 
      object: usuario, 
      fields: ['nombre', 'email', 'rol'],
      search: search
    })
  }

  return (
    <PageLoading isLoading={isLoading}>
      <TableHeader
        actions={(
          <IconButton size='small' color='primary' onClick={handleExport}>
            <i className='material-icons'>download</i>
          </IconButton>
        )}
        title='Usuarios y roles'
        search={search}
        onSearchChange={e=> setSearch(e.target.value)}
      />
      <InformationTable
        size='small'
        details={[
          { title: 'Nombre', key: 'nombre', align: 'left' },
          { title: 'Email', key: 'email', align: 'left' },
          { title: 'Rol', key: 'rol', align: 'left' },
          { title: 'Última conexión', key: 'ultimaConexion', align: 'left' },
        ]}
        data={adminList
          .filter(filterSearch)
          .map(admin=> ({
            nombre: admin.nombre,
            email: admin.email,
            rol: admin.rol,
            ultimaConexion: admin.ultimaConexion
          }))
        }
      />
    </PageLoading>
  )
}

export default Usuarios