import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { useQuery } from "@tanstack/react-query"
import { useNotification } from "../../../context/NotificationManager/NotificationManager"
import { getHorarioCentro } from '../../../utils/api/modelosHorario'
import { dateToString } from '../../../utils/date'
import css from './HorarioSelector.module.css'

const HorarioSelector = ({ 
  className,
  centro,
  fecha,
  name,
  label,
  value,
  onChange 
})=> {

  const notification = useNotification()

  const { isLoading, data: horaList=[] } = useQuery({
    queryKey: ['modelo-horario', centro, fecha], 
    enabled: !!centro && !!fecha,
    queryFn: ()=> getHorarioCentro({ 
      centro, 
      fecha: dateToString(fecha)
    })
      .then(datos=> (datos.horas || []).sort())
      .catch(()=> {
        notification.warning({ 
          title: 'Error al recuperar el horario', 
          content: 'No se ha encontrado un horario para la fecha indicada' 
        })
        return []
      })
  })

  return (
    <Autocomplete
      id={name}
      openOnFocus
      className={`${className} ${css.main}`}
      options={horaList}
      loading={isLoading}
      loadingText='Cargando horas...'
      noOptionsText='No hay horario configurado'
      value={value}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="standard"
        />
      )}
    />
  )
}

export default HorarioSelector