import { GET, POST } from '../request'

export const getAlumno = id => GET('/api/crm/alumnos/alumno', { id })

export const getAlumnoAccesoClassfy = alumno => GET('/api/crm/alumnos/acceso-alumno', { alumno  })

export const getAlumnoName = id => GET('/api/crm/alumnos/alumno/name', { id })

export const getAlumnoListYear = ({ periodo, centro }) => GET(`/api/crm/alumnos/alumno/list/year`, { periodo, centro })

export const getFotoAlumno = ({ alumno }) => GET('/api/crm/alumnos/foto-alumno', { alumno })

export const getNotasAlumnoList = alumno => GET('/api/crm/alumnos/notas-alumno/list', { alumno })

export const getNotasProfesorList = alumno => GET('/api/crm/alumnos/notas-profesor/list', { alumno })

export const createAlumno = (data = {}) => POST('/api/crm/alumnos/alumno', data)