import { useEffect, useState } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link, TextField } from "@mui/material"
import { isAnyBlank } from "../../utils/string"
import FechaSelector from "../../components/form/FechaSelector/FechaSelector"
import HoraSelector from "../../components/form/HoraSelector/HoraSelector"
import { dateToString, dateToTimeString } from "../../utils/date"
import { getReservaPlaza } from "../../utils/documents"
import css from './ModalContactoLeadCualificado.module.css'

const ModalContactoLeadCualificado = ({ open, centro, disabled, onSubmit, onClose }) => {

  const [cualificado, setCualificado] = useState({
    fecha: null,
    hora: null,
    fecha_incorporacion: null,
    presupuesto: '',
    observaciones: '',
  })

  useEffect(()=> {
    setCualificado({
      fecha: null,
      hora: null,
      fecha_incorporacion: null,
      presupuesto: '',
      observaciones: '',
    })
  }, [open])

  const isSubmitDisabled = isAnyBlank(
    cualificado.fecha,
    cualificado.hora,
    cualificado.presupuesto
  )

  const handleSubmit = () => {
    onSubmit({
      lead_status: 'LEAD_CUALIFICADO',
      fecha_lead_cualificado: dateToString(cualificado.fecha),
      fecha_incorporacion_lead_cualificado: dateToString(cualificado.fecha_incorporacion),
      hora_lead_cualificado: dateToTimeString(cualificado.hora),
      presupuesto_lead_cualificado: cualificado.presupuesto,
      observaciones: cualificado.observaciones,
    })
  }

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth='md'>
      <DialogTitle>Datos lead cualificado</DialogTitle>
      <DialogContent className={css.main}>
        <DialogContentText className={css.intro}>
          Rellena los datos asociados al presupuesto ofrecido al lead
        </DialogContentText>
        <DialogContentText className={css.link}>
          <Link 
            className={css.documentLink} 
            href={getReservaPlaza({ academia: centro })} 
            target='_blank'
          >
            <i className='material-icons'>description</i>
            Documento de reserva de plaza
          </Link>
        </DialogContentText>
        <FechaSelector
          className={css.input}
          label='Fecha incorporación estimada'
          value={cualificado.fecha_incorporacion}
          disablePast
          onChange={fecha_incorporacion=> setCualificado({ ...cualificado, fecha_incorporacion })}
        />
        <FechaSelector
          className={css.input}
          label='Fecha próximo contacto'
          value={cualificado.fecha}
          disablePast
          onChange={fecha=> setCualificado({ ...cualificado, fecha })}
        />
        <HoraSelector
          className={css.input}
          label='Hora próximo contacto'
          value={cualificado.hora}
          onChange={(hora)=> setCualificado({ ...cualificado, hora })}
        />
        <TextField
          className={css.input}
          label="Presupuesto ofrecido"
          multiline
          value={cualificado.presupuesto}
          onChange={e=> setCualificado({ ...cualificado, presupuesto: e.target.value })}
          variant='standard'
        />
        <TextField
          className={css.input}
          label="Observaciones"
          multiline
          value={cualificado.observaciones}
          onChange={e=> setCualificado({ ...cualificado, observaciones: e.target.value })}
          variant='standard'
        />
      </DialogContent>
      <DialogActions>
        <Button 
          color='error' 
          disabled={disabled}
          onClick={onClose}
        >
          Cancelar
        </Button>
        <Button 
          disabled={isSubmitDisabled || disabled}
          color='primary' 
          onClick={handleSubmit}
        >
          Anotar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalContactoLeadCualificado