import { useState } from 'react'
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material'
import TextLabel from '../../../../../components/form/TextLabel/TextLabel'
import { dateToStringLong } from '../../../../../utils/date'
import css from './DatosPersonales.module.css'

const DatosPersonales = ({ alumno })=> {

  const [isExpanded, setExpanded] = useState(true)

  const handleChange = (_event, isExpanded) => {
    setExpanded(isExpanded)
  }

  return (
    <Accordion expanded={isExpanded} onChange={handleChange}>
      <AccordionSummary
        expandIcon={<i className='material-icons'>expand_more</i>}
      >
        <div className={css.label}>
          <Typography className={css.title} variant="h2">Información personal</Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className={css.row}>
          <TextLabel
            className={css.field}
            name='dni'
            label='DNI'
            value={alumno.dni}
          />
          <TextLabel
            className={css.field}
            name='codigo_postal'
            label='Código postal'
            value={alumno.codigoPostal}
          />
          <TextLabel
            className={css.field}
            name='fecha_nacimiento'
            label='Fecha de nacimiento'
            value={dateToStringLong(alumno.fechaNacimiento)}
          />
        </div>
        <div className={css.row}>
          <TextLabel
            className={css.field}
            name='fijo'
            label='Teléfono fijo'
            value={alumno.telefonoFijo}
          />
          <TextLabel
            className={css.field}
            name='movil'
            label='Teléfono móvil'
            value={alumno.telefonoMovil}
          />
          <TextLabel
            className={css.field}
            name='email'
            label='E-mail'
            value={alumno.email}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

export default DatosPersonales