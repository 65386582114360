import { dateToString, dateToWeekday, getMonthDaysInfo, isSameDay, WEEKDAYS } from '../../utils/date'
import css from './Calendar.module.css'

const noop = ()=> {}

const NoEvents = ({ placeholder })=> (
  <div className={css.noEvents}>
    {placeholder}
  </div>
)

const Calendar = ({ month, events, placeholder='', onCellClick=noop })=> {

  const active_month = month.split('/')[0]-1
  const weeks = getMonthDaysInfo(month)
  const weekdays = [...WEEKDAYS.slice(1), WEEKDAYS[0]]

  return (
    <>
      <table className={css.calendar}>
        <thead>
          <tr>
            {weekdays.map(weekday=> (
              <th 
                className={css.column}
                key={weekday}
              >
                {weekday}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {weeks.map((week, i)=> (
            <tr className={css.row} key={i}>
              {week.map((day, j)=> (
                <td 
                  className={css.cell}
                  data-calendar-month={day.getMonth() === active_month ? 'same' : 'other'}
                  data-calendar-today={isSameDay(day, new Date()) ? 'yes' : 'no'}
                  key={j}
                  onClick={e=> onCellClick(e, day)}
                >
                  <span className={css.date}>{day.getDate()}</span>
                  <div className={css.cellContent}>
                    {events[dateToString(day)]}
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className={css.mobileCalendar}>
        {weeks.map((week, i)=> (
          <div className={css.mobileRow} key={i}>
           {week.map((day, j)=> (
             <div 
               className={css.mobileCell}
               data-calendar-month={day.getMonth() === active_month ? 'same' : 'other'}
               data-calendar-today={isSameDay(day, new Date()) ? 'yes' : 'no'}
               key={j}
               onClick={e=> onCellClick(e, day)}
             >
               <span className={css.mobileDate}>{dateToWeekday(day)} {day.getDate()}</span>
               <div className={css.mobileContent}>
                 {events[dateToString(day)] || <NoEvents placeholder={placeholder} />}
               </div>
             </div>
           ))}
         </div>
        ))}
      </div>
    </>
  )
}

export default Calendar