export const getTotales = ({ total })=> {
  if (!total) return { alumnos: 0, detalles: [] }
  const detalles = [ 'CIENCIAS', 'LETRAS', 'IDIOMAS', 'OTRAS' ].reduce((acc, curr)=> {
    if (!total[curr]) return acc
    return [
      ...acc, 
      { type: curr, value: total[curr] }
    ]
  }, [])
  return {
    alumnos: detalles.reduce((acc, curr)=> acc + curr.value, 0),
    detalles,
  }
}