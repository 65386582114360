const getCurso = (curso)=> {
  if (curso) return `${curso}º`
  if (curso === 0) return ''
  return '[Curso desconocido]'
}

export const getPerfilAcademico = ({ curso, nivel, centroEstudios }) => {
  const infoCurso = getCurso(curso)
  const infoNivel = nivel ? `${nivel} ` : '[Nivel desconocido] '
  const infoCentro = centroEstudios ? `${centroEstudios}` : '[Centro de estudios desconocido]'
  return `${infoCurso} ${infoNivel} - ${infoCentro}`
}


export const validarAlumno = ({
  nombre,
  apellidos,
  telefono,
  tutor1Movil,
  tutor2Movil,
  movil,
  fechaNacimiento,
  centro,
  lead,
})=> {
  const errors = []
  if (!nombre) errors.push('Es necesario indicar el nombre del alumno')
  if (!apellidos) errors.push('Es necesario indicar el apellido del alumno')
  if (!telefono && !tutor1Movil && !tutor2Movil && !movil) {
    errors.push('Es necesario indicar un teléfono de contacto')
  }
  if (!fechaNacimiento) errors.push('Es necesario indicar la fecha de nacimiento del alumno')
  if (!centro) errors.push('Es necesario indicar el centro asociado al alumno')
  if (!lead) errors.push('Es necesario indicar el lead asociado al alumno')
  return {
    status: errors.length === 0 ? 'ok' : 'error',
    errors,
  }
}