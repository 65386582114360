import { useState } from "react"
import { useQuery } from "@tanstack/react-query"
import { Alert, IconButton } from "@mui/material"
import InformationTable from "../../../components/Table/InformationTable"
import TableHeader from "../../../components/TableHeader/TableHeader"
import PageLoading from "../../../components/PageLoading/PageLoading"
import FechaSelector from "../../../components/form/FechaSelector/FechaSelector"
import { dateToDateTime, dateToString, getToday } from "../../../utils/date"
import { textFilter } from "../../../utils/table"
import { getPruebas } from "../../../utils/api/leads"
import { downloadCSVFile, getCSVContent } from "../../../utils/csv"
import { useNotification } from "../../../context/NotificationManager/NotificationManager"
import { capitalize } from "../../../utils/string"
import css from "./Pruebas.module.css"
import { Link } from "react-router-dom"
import { getSituacion } from "../../../utils/model/leads"

const Pruebas = () => {
  const [fecha, setFecha] = useState(getToday())
  const [search, setSearch] = useState("")

  const notification = useNotification()

  const { isLoading, data: pruebaList = [] } = useQuery({
    queryKey: ["marketing", "feedback", "list", fecha],
    queryFn: () =>
      getPruebas({ fecha: dateToString(fecha) })
        .catch((err) => {
          notification.error({ title: "Error al recuperar las pruebas", content: err, })
          return []
        }),
    enabled: !!fecha,
  })

  const handleExport = () => {
    const content = getCSVContent({
      columns: [ "Contacto", "Relacion", "Alumno", "Centro", "Nº Contactos", "Estado", "Teléfono"],
      data: pruebaList,
      transform: (p) => [
        p.contacto,
        p.relacion,
        p.alumno,
        p.centro,
        p.contactos,
        p.estado,
        p.telefono,
      ],
    })
    downloadCSVFile(content, `seguimiento-pruebas-${dateToString(fecha)}`)
  }

  const filterSearch = (prueba) => {
    return textFilter({
      object: prueba,
      fields: ["alumno", "estado"],
      search: search,
    })
  }

  const totalLeadsCaptados = pruebaList.filter(lead=> getSituacion(lead.estado) === 'Captado').length
  const totalLeadsPerdidos = pruebaList.filter(lead=> getSituacion(lead.estado) === 'Perdido').length
  const totalLeadsAbiertos = pruebaList.filter(lead=> getSituacion(lead.estado) === 'Abierto').length

  return (
    <PageLoading isLoading={isLoading}>
      <TableHeader
        actions={
          <div className={css.actions}>
            <IconButton
              className={css.download}
              size="small"
              color="primary"
              disabled={!fecha}
              onClick={handleExport}
            >
              <i className="material-icons">download</i>
            </IconButton>
            <FechaSelector
              label="Fecha"
              value={dateToDateTime(fecha)}
              onChange={setFecha}
            />
          </div>
        }
        title="Seguimiento pruebas"
        search={search}
        onSearchChange={(e) => setSearch(e.target.value)}
      />
      <Alert className={css.banner} severity='info'>
        Hay un total de {pruebaList.length} pruebas ({totalLeadsCaptados} convertidas | {totalLeadsPerdidos} perdidas | {totalLeadsAbiertos} abiertas)
      </Alert>
      <InformationTable
        isLoading={isLoading}
        details={[
          { title: "Lead", key: "contacto", sortDisabled: true },
          { title: "Estado", key: "estado" },
          { title: "Teléfono", key: "telefono"},
          { title: "Centro", key: "centro" },
        ]}
        data={pruebaList.filter(filterSearch).map((prueba) => ({
          contacto: (
            <Link className={css.link} to={`/leads/${prueba.id}/ficha`}>
              {prueba.contacto}
              {prueba.relacion && ` (${prueba.relacion})`} 
            </Link>
          ),
          estado: capitalize(prueba.estado.replaceAll('_', ' ')),
          telefono: prueba.telefono,
          centro: prueba.centro,
        }))}
      />
    </PageLoading>
  )
}

export default Pruebas
