import { useState } from "react"
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material"
import { isAnyBlank } from "../../utils/string"
import css from "./ModalEditarPasswordAccesoClassfy.module.css"

const ModalEditarPasswordAccesoClassfy = ({ open, onClose, onSubmit }) => {

  const [password, setPassword] = useState("")

  const isSubmitDisabled = isAnyBlank(password)
  
  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth="sm">
      <DialogTitle>Cambiar contraseña de acceso a Classfy</DialogTitle>
      <DialogContent>
        <Alert
          icon={<i className="material-icons">warning</i>}
          className={css.alert}
          severity="warning"
        >
          Si se modifica la contraseña de acceso del alumno, se le cerrarán todas las sesiones 
          y tendrá que volver a iniciar sesión utilizando la nueva contraseña.
          <br />
          El alumno no será notificado del cambio de contraseña, por lo que es importante notificarle directamente
        </Alert>
        <div className={css.info}>
          <TextField
            label="Nueva contraseña de acceso"
            value={password} 
            variant='standard'
            onChange={(event)=> setPassword(event.target.value)}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={onClose}>
          Cancelar
        </Button>
        <Button 
          color="primary" 
          onClick={() => onSubmit(password)} 
          disabled={isSubmitDisabled}>
          Modificar contraseña
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalEditarPasswordAccesoClassfy
