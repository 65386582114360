import { IconButton } from '@mui/material'
import { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { downloadCSVFile, getCSVContent } from '../../../utils/csv'
import PageLoading from '../../../components/PageLoading/PageLoading'
import InformationTable from '../../../components/Table/InformationTable'
import TableHeader from '../../../components/TableHeader/TableHeader'
import { useNotification } from '../../../context/NotificationManager/NotificationManager'
import MesRecienteSelector from '../../../components/form/MesRecienteSelector/MesRecienteSelector'
import AcademiaSelector from '../../../components/form/AcademiaSelector/AcademiaSelector'
import { getAuth } from '../../../utils/auth'
import { dateToString, monthToString } from '../../../utils/date'
import { textFilter } from '../../../utils/table'
import { getEstado } from '../../../utils/model/leads'
import { deleteLead, getLeadsMonth } from '../../../utils/api/leads'
import css from './Leads.module.css'

const LeadCerrado = ()=> (
  <span className={css.cerrado}>Lead cerrado</span>
)

const Leads = ()=> {

  const notification = useNotification()
  const queryClient = useQueryClient()

  const [mes, setMes] = useState(monthToString(new Date()))
  const [centro, setCentro] = useState(getAuth().centro)

  const [search, setSearch] = useState('')

  const { isLoading: isLoadingLeads, data: leadList=[] } = useQuery({
    queryKey: ['feedback', 'list', 'CRM', mes, centro], 
    enabled: !!mes && !!centro,
    queryFn: ()=> getLeadsMonth({ mes, centro })
      .then(datos=> datos)
      .catch(err=> {
        notification.error({ title: 'Error al recuperar los leads', content: err })
        return []
      })
  })

  const { isFetching: isDeleting, mutate: borrarLead } = useMutation({
    mutationFn: deleteLead,
    onSuccess: ()=> {
      notification.success({ title: 'Lead borrado', content: 'El lead ha sido borrado correctamente' })
      queryClient.invalidateQueries(['feedback', 'list', 'CRM', mes, centro])
    },
    onError: err=> notification.error({ title: 'Error al borrar el lead', content: err }),
  })


  const handleExport = ()=> {
    const content = getCSVContent({
      columns: ['Nombre Lead', 'Teléfono', 'Email', 'Total Contactos', 'Próximo Contacto', 'Estado Lead' ],
      data: leadList,
      transform: lead=> ([
        lead.contacto,
        lead.telefono,
        lead.email,
        lead.contactos,
        lead.fecha ? 'Lead Cerrado' : dateToString(lead.fecha)
      ])
    })
    downloadCSVFile(content, 'leads-mes')
  }

  const handleLeadDelete = (id)=> {
    if (isDeleting) return
    borrarLead(id)
  }

  const filterSearch = lead=> {
    return textFilter({ 
      object: lead, 
      fields: ['contacto', 'telefono', 'email', 'estado'],
      search: search
    })
  }

  return (
    <PageLoading isLoading={isLoadingLeads}>
      <TableHeader
        actions={(
          <div className={css.actions}>
            <IconButton className={css.download} size='small' color='primary' onClick={handleExport}>
              <i className='material-icons'>download</i>
            </IconButton>
            <AcademiaSelector
              name='academia'
              label='Academia'
              value={centro}
              onChange={e=> setCentro(e.target.value)}
            />
            <MesRecienteSelector
              className={css.mes}
              name='mes'
              label='Mes'
              value={mes}
              onChange={e=> setMes(e.target.value)}
            />
          </div>
        )}
        title='Leads entrantes'
        search={search}
        onSearchChange={e=> setSearch(e.target.value)}
      />
      <InformationTable
        details={[
          { title: 'Lead', key: 'lead', align: 'left' },
          { title: 'Teléfono', key: 'telefono', sortDisabled: true, align: 'left' },
          { title: 'Email', key: 'email', sortDisabled: true, align: 'left' },
          { title: 'Contactos', key: 'contactos', sortDisabled: true, align: 'left' },
          { title: 'Próximo contacto', key: 'fecha', sortDisabled: true, align: 'left' },
          { title: 'Estado', key: 'estado', align: 'left' },
          { title: 'Opciones', key: 'opciones', sortDisabled: true, align: 'center' },
        ]}
        data={leadList
          .filter(filterSearch)
          .map(lead=> ({
            lead: lead.contacto,
            telefono: lead.telefono,
            email: lead.email,
            contactos: lead.contactos,
            fecha: lead.fecha ? dateToString(lead.fecha) : <LeadCerrado />,
            estado: <strong>{getEstado(lead.estado)}</strong>,
            opciones: (
              <IconButton size='small' color='error' onClick={()=> handleLeadDelete(lead.id)}>
                <i className='material-icons'>delete</i>
              </IconButton>
            )
          }))
        }
      />
    </PageLoading>
  )

}

export default Leads