import { useState } from 'react'
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material'
import TextLabel from '../../../../../components/form/TextLabel/TextLabel'
import css from './DatosFacturacion.module.css'

const DatosFacturacion = ({ 
  facturacion,
 })=> {

  const [isExpanded, setExpanded] = useState(true)

  const handleChange = (_event, isExpanded) => {
    setExpanded(isExpanded)
  }

  const { nombre='', identificacion='' } = facturacion || {}

  return (
    <Accordion expanded={isExpanded} onChange={handleChange}>
      <AccordionSummary
        expandIcon={<i className='material-icons'>expand_more</i>}
      >
        <div className={css.label}>
          <Typography className={css.title} variant="h2">Datos de facturación</Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className={css.row}>
          <TextLabel
            className={css.field}
            name='nombre'
            label='Titular factura'
            value={nombre}
          />
          <TextLabel
            className={css.field}
            name='identificacion'
            label='Identificación factura (CIF / NIF)'
            value={identificacion}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

export default DatosFacturacion