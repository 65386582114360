import { useState } from "react"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  TextField,
  Typography,
} from "@mui/material"
import { isAnyBlank } from "../../../../../utils/string"
import css from "./ConfigurarAcceso.module.css"

const ConfigurarAcceso = ({ onSubmit }) => {

  const [email, setEmail] = useState("")

  const isSubmitDisabled = isAnyBlank(email)

  const handleSubmit = () => {
    onSubmit(email)
    setEmail("") 
  }

  return (
    <Accordion expanded>
      <AccordionSummary>
        <div className={css.label}>
          <Typography className={css.title} variant="h2">
            Configurar acceso a Classfy
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Alert 
          icon={<i className='material-icons'>warning</i>} 
          className={css.alert} 
          severity='warning'
        >
          Este lead no tiene configurado acceso a Classfy. Introduce su <strong> e-mail </strong> para darle acceso.
        </Alert>
        <div className={css.form}>
          <TextField
            className={css.field}
            label="Email"
            value={email} 
            onChange={e => setEmail(e.target.value)}
            variant='standard'
          />
        </div>
        <div className={css.button}>
          <Button 
            color="primary"  
            disabled={isSubmitDisabled}
            onClick={handleSubmit}
          >
            Crear acceso
          </Button>
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

export default ConfigurarAcceso
