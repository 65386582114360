import { useEffect, useState } from "react"
import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from "@mui/material"
import css from "./ModalEditarAccesosClassfy.module.css"

const ModalEditarAccesosClassfy = ({ open, accesoCliente, onClose, onSubmit }) => {

  const [accesoClassfy, setAccesoClassfy] = useState(false)
  const [accesoApp, setAccesoApp] = useState(false)
  const [accesoBarbeibot, setAccesoBarbeibot] = useState(false)

  useEffect(() => {
    if (!accesoCliente) return
    setAccesoClassfy(accesoCliente.accesoClassfy)
    setAccesoApp(accesoCliente.accesoApp)
    setAccesoBarbeibot(accesoCliente.accesoBarbeibot)
  }, [open, accesoCliente])

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth="sm">
      <DialogTitle>Editar datos de acceso a Classfy</DialogTitle>
      <DialogContent>
        <Alert
          icon={<i className="material-icons">warning</i>}
          className={css.alert}
          severity="warning"
        >
          Si se modifican los accesos del alumno se le cerrarán todas las sesiones y tendrá que volver
          a iniciar sesión.
        </Alert>
        <div className={css.info}>
          <FormControlLabel
            control={(
              <Checkbox 
                checked={accesoClassfy}
                onChange={(event)=> setAccesoClassfy(event.target.checked)}
              />
            )} 
            label='Acceso a Classfy'
          />
          <FormControlLabel
            control={(
              <Checkbox 
                checked={accesoApp}
                onChange={(event)=> setAccesoApp(event.target.checked)}
              />
            )} 
            label='Acceso a App'
          />
          <FormControlLabel
            control={(
              <Checkbox 
                checked={accesoBarbeibot}
                onChange={(event)=> setAccesoBarbeibot(event.target.checked)}
              />
            )} 
            label='Acceso a Barbeibot'
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={onClose}>
          Cancelar
        </Button>
        <Button 
          color="primary" 
          onClick={() => onSubmit({ accesoClassfy, accesoApp, accesoBarbeibot })} 
        >
          Actualizar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalEditarAccesosClassfy
