import { createTheme } from '@mui/material/styles'

export default createTheme({
  palette: {
    primary: {
      main: '#8cc63f',
      light: '#bdd736',
      dark: '#18ab50',
      contrastText: '#fff'
    },
    secondary: {
      main: '#f89f41',
      dark: '#ef5938',
      contrastText: '#fff'
    },
    error: {
      main: '#f0513c',
      dark: '#ee2a3e'
    },
    warning: {
      main: '#f89f41',
      contrastText: '#fff'
    },
    success: {
      main: '#17ab7d'
    }
  },
})