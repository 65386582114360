
import { Button, Card, CardContent, Chip, IconButton, Tooltip, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import Calendar from '../../components/Calendar/Calendar'
import PackHorasSelector from '../../components/form/PackHorasSelector/PackHorasSelector'
import TarifaSelector from '../../components/form/TarifaSelector/TarifaSelector'
import AsignaturaSelector from '../../components/form/AsignaturaSelector/AsignaturaSelector'
import PageLoading from '../../components/PageLoading/PageLoading'
import { dateToMonth, dateToString, forwardMonths, getStartOfMonth, isSameMonth, monthToString } from '../../utils/date'
import { getColor, getRecomendaciones } from '../../utils/model/asignaturas'
import { getAsignaturas } from '../../utils/api/asignaturas'
import { useNotification } from '../../context/NotificationManager/NotificationManager'
import CodigoPromocionalSelector from '../../components/form/CodigoPromocionalSelector/CodigoPromocionalSelector'
import { getPresupuesto } from '../../utils/api/presupuestos'
import { getPresupuesto as getPresupuestoDocument } from '../../utils/documents'
import AcademiaSelector from '../../components/form/AcademiaSelector/AcademiaSelector'
import css from './Presupuestador.module.css'

const CalendarEvent = ({ asistencia, onClick })=> (
  <div 
    className={css.calendarEvent} 
    data-color={asistencia.color}
    onClick={e=> {
      e.preventDefault()
      e.stopPropagation()
    }}
    onDoubleClick={onClick}
  >
    <p className={css.calendarEventNombre}>{asistencia.asignaturaNombre}</p>
    <p className={css.calendarEventNivel}>{asistencia.asignaturaNivel}</p>
  </div>
)


const Presupuestador = ()=> {

  const notification = useNotification()

  const [centro, setCentro] = useState('')
  const [pack, setPack] = useState('')
  
  const [mes, setMes] = useState(getStartOfMonth())
  const [tarifa, setTarifa] = useState('')
  const [codigoPromocional, setCodigoPromocional] = useState('')
  const [asignatura, setAsignatura] = useState({})
  const [asignaturasRecomendadas, setAsignaturasRecomendadas] = useState([])

  const [asistenciasCalendario, setAsistenciasCalendario] = useState([])

  const { data: asignaturaList=[] } = useQuery({
    queryKey: ['asignatura', 'list'], 
    queryFn: ()=> getAsignaturas()
      .then(datos=> datos
        .map(({ id, nombre, nivel, subnivel })=> {
          if (subnivel) return { id, nombre: `${nombre} (${nivel} - ${subnivel})` }
          return { id, nombre: `${nombre} (${nivel})` }
        })
        .sort((a1, a2)=> a1.nombre.localeCompare(a2.nombre))
      )
      .catch(err=> {
        notification.error({ title: 'Error al recuperar las asignaturas', content: err })
        return []
      })
  })
  
  const { isUpdating: isPresupuestoCreating, mutate: createPresupuesto } = useMutation({
    mutationFn: getPresupuesto,
    onSuccess: (presupuesto) => {
      const url = getPresupuestoDocument({
        academia: presupuesto.centro,
        mes: monthToString(mes), 
        alumno: 'alumno', 
        matricula: 45, 
        tarifa_nombre: presupuesto.tarifa, 
        tarifa_precio: presupuesto.precio, 
        tarifa_condiciones: presupuesto.condiciones, 
        tarifa_base_precio: presupuesto.precioTarifaBase,
        promocion: presupuesto.promocion || '', 
        promocion_precio: presupuesto.precioPromocion || '', 
        promocion_descripcion: presupuesto.descripcionPromocion || ''
      })
      window.open(url, '_blank')
    },
    onError: err => {
      notification.error({ title: 'Error generando presupuesto', content: err })
    },
  })

  const handleChangeMonth = (change)=> ()=> {
    const newMes = forwardMonths(mes, change)
    setMes(newMes)
  }

  const handleOpenPack = ()=> {
    const url = pack.urlPromocion[centro]
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  const handleClearCalendar = ()=> {
    setAsistenciasCalendario([])
  }

  const handleCellClick = (event, date)=> {
    if (!asignatura || !asignatura.id) return
    if (!isSameMonth(date, mes)) return
    const [, nombre, nivel] = asignatura.nombre.match(/^(.+) \((.+)\)$/)

    // Si ha pulsado ⇧, añadimos 2h y vamos avanzando 7 días y añadiendo 2h
    if (event.shiftKey) {
      const asistencias = []
      const asistencia = {
        asignatura: asignatura.id,
        asignaturaNombre: nombre,
        asignaturaNivel: nivel,
        color: getColor(nombre)
      }
      const currentDate = new Date(date)
      while (isSameMonth(currentDate, mes)) {
        const fecha = dateToString(currentDate)
        asistencias.push({ ...asistencia, fecha })
        asistencias.push({ ...asistencia, fecha })
        currentDate.setDate(currentDate.getDate() + 7)
      }
      setAsistenciasCalendario([...asistenciasCalendario, ...asistencias])
    }
    // Añadimos una asistencia
    else {
      const fecha = dateToString(date)
      const asistencia = {
        fecha,
        asignatura: asignatura.id,
        asignaturaNombre: nombre,
        asignaturaNivel: nivel,
        color: getColor(nombre)
      }
      setAsistenciasCalendario([...asistenciasCalendario, asistencia])
    }
  }

  useEffect(()=> {
    const asignaturas = getRecomendaciones(asignatura.id)
    setAsignaturasRecomendadas(asignaturas)
  }, [asignatura])

  const events = asistenciasCalendario.reduce((acc, asistencia, index)=> {
    const datosFecha = acc[asistencia.fecha] || []

    const handleRemove = ()=> {
      const newAsistencias = asistenciasCalendario.filter((_a, i)=> i !== index)
      setAsistenciasCalendario(newAsistencias)
    }

    return {
      ...acc,
      [asistencia.fecha]: [
        ...datosFecha, 
        <CalendarEvent
          key={index}
          asistencia={asistencia} 
          onClick={handleRemove}
        />
      ]
    }
  }, {})

  const handlePresupuestarTarifa = ()=> {
    if (isPresupuestoCreating) return
    createPresupuesto({
      tarifa,
      codigo: codigoPromocional,
      asistencias: asistenciasCalendario.map(({ fecha, asignatura })=> ({ fecha, asignatura })),
      centro: centro,
    })
  }

  return (
    <PageLoading isLoading={false}>
      <div className={css.presupuestador}>
        <div className={css.sidebar}>
          <AcademiaSelector
            className={css.academyInput}
            name='academia'
            label='Academia'
            value={centro}
            onChange={e=> setCentro(e.target.value)}
          />
          <Card className={css.sidebarCard} elevation={4}>
            <CardContent className={css.cardContent}>
              <Typography variant='h6' className={css.cardTitle}>
                Presupuestar pack de horas
              </Typography>
              <div className={css.cardPackHoras}>
                <div className={css.packSelector}>
                  <PackHorasSelector
                    className={css.inputPack}
                    name='pack'
                    label='Seleccionar pack'
                    value={pack}
                    onChange={e=> setPack(e.target.value)}
                  />
                  <IconButton 
                    className={css.openPack} 
                    size='small' 
                    color='primary'
                    onClick={handleOpenPack}
                    disabled={!pack || !centro}
                  >
                    <i className='material-icons'>launch</i>
                  </IconButton>
                </div>
              </div>
            </CardContent>
          </Card>
          <Card className={css.sidebarCard} elevation={4}>
            <CardContent className={css.cardContent}>
              <Typography variant='h6' className={css.cardTitle}>
                Presupuestar tarifa
              </Typography>
              <div className={css.cardTarifa}>
                <TarifaSelector
                  className={css.inputTarifa}
                  name='tarifa'
                  label='Seleccionar tarifa'
                  value={tarifa}
                  onChange={e=> setTarifa(e.target.value)}
                />
                <CodigoPromocionalSelector
                  className={css.inputTarifa}
                  name='codigoPromocional'
                  label='Seleccionar promoción'
                  value={codigoPromocional}
                  onChange={e=> setCodigoPromocional(e.target.value)}
                />
                <AsignaturaSelector
                  className={css.inputTarifa}
                  name='asignatura'
                  label='Seleccionar asignatura'
                  value={asignatura.id}
                  onChange={(_e, value)=> setAsignatura(value || '')}
                />
                {asignaturasRecomendadas.length > 0 && (
                  <div className={css.recomendaciones}>
                    <Tooltip title="Recomenda-bot sugiere las siguientes asignaturas">
                      <Chip
                        key={asignatura.id} 
                        label='🤖' 
                        size="small"
                        variant="outlined" 
                      />
                    </Tooltip>
                    {asignaturasRecomendadas
                      .map(asignatura=> asignaturaList.find(a=> a.id === asignatura))
                      .filter(Boolean)
                      .map(asignatura=> (
                        <Chip
                          key={asignatura.id} 
                          size="small"
                          variant="outlined" 
                          className={css.recomendacion}
                          label={asignatura.nombre} 
                          onClick={()=> setAsignatura(asignatura)}
                        />
                      ))
                    }
                  </div>
                )}
                <div className={css.cardTarifaButtons}>
                  <Button 
                    className={css.cardTarifaButton}
                    disabled={!asistenciasCalendario.length || isPresupuestoCreating}
                    onClick={handleClearCalendar}
                    color='error' 
                    variant="outlined"
                  >
                    Borrar calendario
                  </Button>
                  <Button 
                    onClick={handlePresupuestarTarifa}
                    disabled={!asistenciasCalendario.length || !centro || isPresupuestoCreating}
                    className={css.cardTarifaButton} 
                    color='primary' 
                    variant="outlined"
                  >
                    Presupuestar
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
        <div className={css.calendar}>
          <div className={css.monthSelection}>
            <IconButton size='small' onClick={handleChangeMonth(-1)}>
              <i className='material-icons'>arrow_back</i>
            </IconButton>
            <p className={css.calendarMonth}>
              {dateToMonth(mes)}
            </p>
            <IconButton size='small' onClick={handleChangeMonth(+1)}>
              <i className='material-icons'>arrow_forward</i>
            </IconButton>
          </div>
          <Calendar
            month={monthToString(mes)}
            onCellClick={handleCellClick}
            events={events}
          />
        </div>
      </div>
    </PageLoading>
  )
}

export default Presupuestador