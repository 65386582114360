const EstadoAsistencia = ({ asistencia })=> {
  if (asistencia.asistencia === 'POR_DEFINIR') {
    return (
      <i className='material-icons' data-asistencia='POR_DEFINIR'>help_outline</i>
    )
  }
  else if (asistencia.asistencia === 'VIENE') {
    return (
      <i className='material-icons' data-asistencia='VIENE'>check</i>
    )
  }
  else if (asistencia.asistencia === 'FALTA_JUSTIFICADA') {
    return (
      <i className='material-icons' data-asistencia='FALTA_JUSTIFICADA'>highlight_off</i>
    )
  }
  else if (asistencia.asistencia === 'FALTA') {
    return (
      <i className='material-icons' data-asistencia='FALTA'>close</i>
    )
  }
  return null

}

export default EstadoAsistencia