import { useState } from "react"
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, Typography } from "@mui/material"
import css from './Accesos.module.css'


const Accesos = ({accesoAlumno}) => {
    const [isExpanded, setExpanded] = useState(true)

    const handleChange = (_event, isExpanded) => {
        setExpanded(isExpanded)
      }
  return (
    <Accordion expanded={isExpanded} onChange={handleChange}>
    <AccordionSummary
      expandIcon={<i className="material-icons">expand_more</i>}
    >
      <div className={css.label}>
        <Typography className={css.title} variant="h2">
          Accesos
        </Typography>
      </div>
    </AccordionSummary>
    <AccordionDetails>
      <div className={css.checks}>
      <FormControlLabel
          control={(
            <Checkbox checked={accesoAlumno.accesoClassfy} />
          )} 
          label={(
            <>
              <strong>Classfy</strong> - El alumno podrá iniciar sesión en Classfy.
            </>
          )}
        />
         <FormControlLabel
          control={(
            <Checkbox checked={accesoAlumno.accesoApp} />
          )} 
          label={(
            <>
              <strong>App</strong> - El alumno podrá iniciar sesión en la app de mundoestudiante.
            </>
          )}
        />
        <FormControlLabel
          control={(
            <Checkbox checked={accesoAlumno.accesoBarbeibot} />
          )} 
          label={(
            <>
              <strong>Barbeibot</strong> - El alumno podrá conversar con la IA de mundoestudiante.
            </>
          )}
        />
      </div>
    </AccordionDetails>
  </Accordion>
  )
}

export default Accesos
