import { Accordion, AccordionDetails, AccordionSummary, IconButton } from '@mui/material'
import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { downloadCSVFile, getCSVContent } from '../../../utils/csv'
import PageLoading from '../../../components/PageLoading/PageLoading'
import InformationTable from '../../../components/Table/InformationTable'
import TableHeader from '../../../components/TableHeader/TableHeader'
import { useNotification } from '../../../context/NotificationManager/NotificationManager'
import AcademiaSelector from '../../../components/form/AcademiaSelector/AcademiaSelector'
import { dateToString } from '../../../utils/date'
import { textFilter } from '../../../utils/table'
import { getEstado } from '../../../utils/model/leads'
import { getLeadsAnalysis } from '../../../utils/api/leads'
import AsignaturaSelector from '../../../components/form/AsignaturaSelector/AsignaturaSelector'
import FechaSelector from '../../../components/form/FechaSelector/FechaSelector'
import ComoConoceSelector from '../../../components/form/ComoConoceSelector/ComoConoceSelector'
import ObjetivoAlumnoSelector from '../../../components/form/ObjetivoAlumnoSelector/ObjetivoAlumnoSelector'
import CentroEstudiosSelector from '../../../components/form/CentroEstudiosSelector/CentroEstudiosSelector'
import css from './Segmentacion.module.css'

const LeadCerrado = ()=> (
  <span className={css.cerrado}>Lead cerrado</span>
)

const Segmentacion = ()=> {
  const notification = useNotification()

  const [fechaInicio, setFechaInicio] = useState(null)
  const [fechaFin, setFechaFin] = useState(null)
  const [asignatura, setAsignatura] = useState(null)
  const [centros, setCentros] = useState('')
  const [comoConoce, setComoConoce] = useState('')
  const [objetivo, setObjetivo] = useState('')
  const [centroEstudios, setCentroEstudios] = useState(null)
  const [estado, setEstado] = useState('')

  const [search, setSearch] = useState('')

  const filters = { 
    fecha_inicio: dateToString(fechaInicio), 
    fecha_fin: dateToString(fechaFin), 
    asignatura: asignatura ? asignatura.id : null, 
    'centros[]': [ centros ],
    como_conoce: comoConoce,
    objetivo: objetivo,
    centro_estudios: centroEstudios ? centroEstudios.id : null,
    estado
  }

  const { isLoading: isLoadingLeads, data: leadList=[] } = useQuery({
    queryKey: ['feedback', 'list', 'analysis', filters], 
    enabled: !!(fechaInicio && fechaFin),
    queryFn: ()=> getLeadsAnalysis(filters)
      .then(datos=> datos)
      .catch(err=> {
        notification.error({ title: 'Error al recuperar los leads', content: err })
        return []
      })
  })

  const handleExport = ()=> {
    const content = getCSVContent({
      columns: ['Nombre Lead', 'Teléfono', 'Email', 'Total Contactos', 'Próximo Contacto', 'Estado Lead' ],
      data: leadList,
      transform: lead=> ([
        lead.contacto,
        lead.telefono,
        lead.email,
        lead.contactos,
        lead.fecha ? 'Lead Cerrado' : dateToString(lead.fecha)
      ])
    })
    downloadCSVFile(content, 'leads-mes')
  }

  const filterSearch = lead=> {
    return textFilter({ 
      object: lead, 
      fields: ['contacto', 'telefono', 'email', 'estado'],
      search: search
    })
  }

  const handleResetFilters = (e)=> {
    e.stopPropagation()
    setFechaInicio(null)
    setFechaFin(null)
    setAsignatura(null)
    setCentros('')
    setComoConoce('')
    setObjetivo('')
    setCentroEstudios(null)
    setEstado('')
  }

  return (
    <PageLoading isLoading={isLoadingLeads}>
      <TableHeader
        actions={(
          <div className={css.actions}>
            <IconButton className={css.download} size='small' color='primary' onClick={handleExport}>
              <i className='material-icons'>download</i>
            </IconButton>
          </div>
        )}
        title='Análisis de leads'
        search={search}
        onSearchChange={e=> setSearch(e.target.value)}
      />
      <div className={css.filters}>
        <Accordion elevation={4}>
          <AccordionSummary
            expandIcon={<i className='material-icons'>expand_more</i>}
          >
            <div className={css.filterSummary}>
              <p className={css.filterTitle}>Filtros</p>
              <div className={css.filterActions}>
                <IconButton size='small' color='warning' onClick={handleResetFilters}>
                  <i className='material-icons'>refresh</i>
                </IconButton>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <section className={css.row}>
              <FechaSelector 
                className={css.input}
                label='Fecha inicio' 
                value={fechaInicio} 
                onChange={setFechaInicio} 
              />
              <FechaSelector 
                className={css.input}
                label='Fecha fin' 
                value={fechaFin} 
                onChange={setFechaFin} 
              />
            </section>
            <section className={css.row}>
              <AcademiaSelector
                className={css.input}
                name="academia" 
                label="Academia" 
                value={centros} 
                onChange={e=> setCentros(e.target.value)} 
              />
              <AsignaturaSelector 
                className={css.input}
                name="asignatura"
                label="Asignatura"
                value={asignatura} 
                onChange={(_e, value)=> setAsignatura(value)} 
              />
            </section>
            <section className={css.row}>
              <CentroEstudiosSelector 
                className={css.input}
                value={centroEstudios} 
                name='centroEstudios'
                label='Centro de estudios'
                onChange={(_event, value)=> setCentroEstudios(value)}
              />
              <ComoConoceSelector 
                className={css.input}
                value={comoConoce} 
                label="Como conoce"
                name='como_conoce'
                onChange={e=> setComoConoce(e.target.value)} 
              />
              <ObjetivoAlumnoSelector 
                className={css.input}
                value={objetivo}
                label="Objetivo"
                name='objetivo'
                onChange={e=> setObjetivo(e.target.value)} 
              />
            </section>
          </AccordionDetails>
        </Accordion>
      </div>
      <InformationTable
        emptyText='No hay leads que coincidan con los filtros establecidos'
        details={[
          { title: 'Lead', key: 'lead', align: 'left' },
          { title: 'Teléfono', key: 'telefono', sortDisabled: true, align: 'left' },
          { title: 'Email', key: 'email', sortDisabled: true, align: 'left' },
          { title: 'Contactos', key: 'contactos', sortDisabled: true, align: 'left' },
          { title: 'Próximo contacto', key: 'fecha', sortDisabled: true, align: 'left' },
          { title: 'Estado', key: 'estado', align: 'left' },
        ]}
        data={leadList
          .filter(filterSearch)
          .map(lead=> ({
            lead: lead.contacto,
            telefono: lead.telefono,
            email: lead.email,
            contactos: lead.contactos,
            fecha: lead.fecha ? dateToString(lead.fecha) : <LeadCerrado />,
            estado: <strong>{getEstado(lead.estado)}</strong>,
          }))
        }
      />
    </PageLoading>
  )


  
}

export default Segmentacion