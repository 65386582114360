
import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import EjercicioSelector from '../../../components/form/EjercicioSelector/EjercicioSelector'
import MesSelector from '../../../components/form/MesSelector/MesSelector'
import PageLoading from '../../../components/PageLoading/PageLoading'
import TableHeader from '../../../components/TableHeader/TableHeader'
import { useNotification } from '../../../context/NotificationManager/NotificationManager'
import AcademiaSelector from '../../../components/form/AcademiaSelector/AcademiaSelector'
import { getAuth } from '../../../utils/auth'
import SmallTable from '../../../components/Table/SmallTable'
import { getLeadList } from '../../../utils/api/leads'
import css from './Comportamiento.module.css'

const ComoConoceTable = ({ leads }) => (
  <SmallTable
    details={[
      { title: 'Cómo ha conocido mundoestudiante', key: 'conoce', align: 'left' },
      { title: 'Leads', key: 'total', align: 'center' },
      { title: 'Leads (%)', key: 'porcentaje', align: 'center' }
    ]}
    data={(leads || [])
      .reduce((acc, lead)=> {
        const conoce = lead.comoConoce || '[Desconocido]'
        if (acc.find(c=> c.conoce === conoce)) {
          acc.find(c=> c.conoce === conoce).leads++
        }
        else {
          acc.push({ conoce, leads: 1 })
        }
        return acc
      }, [])
      .filter(c=> c.leads > Math.max(2, leads.length/20))
      .sort((a, b)=> b.leads - a.leads)
      .map(lead=> ({
        conoce: lead.conoce.replace('_', ' '),
        total: lead.leads,
        porcentaje: `${(lead.leads/leads.length*100).toFixed(2)} %`
      }))
    }
  />
)

const ObjetivosTable = ({ leads })=> (
  <SmallTable
    details={[
      { title: 'Objetivo académico indicado', key: 'objetivo', align: 'left' },
      { title: 'Leads', key: 'total', align: 'center' },
      { title: 'Leads (%)', key: 'porcentaje', align: 'center' }
    ]}
    data={(leads || [])
      .reduce((acc, lead)=> {
        const objetivo = (lead.objetivos === '--' ? '[Desconocido]' : lead.objetivos) || '[Desconocido]'
        if (acc.find(c=> c.objetivo === objetivo)) {
          acc.find(c=> c.objetivo === objetivo).leads++
        }
        else {
          acc.push({ objetivo, leads: 1 })
        }
        return acc
      }, [])
      .filter(c=> c.leads > Math.max(2, leads.length/20))
      .sort((a, b)=> b.leads - a.leads)
      .map(lead=> ({
        objetivo: lead.objetivo,
        total: lead.leads,
        porcentaje: `${(lead.leads/leads.length*100).toFixed(2)} %`
      }))
    }
  />
)

const NivelTable = ({ leads })=> (
  <SmallTable
    details={[
      { title: 'Nivel de estudios indicado', key: 'nivel', align: 'left' },
      { title: 'Leads', key: 'total', align: 'center' },
      { title: 'Leads (%)', key: 'porcentaje', align: 'center' }
    ]}
    data={(leads || [])
      .reduce((acc, lead)=> {
        const nivel = lead.tipologiaAcademica || '[Desconocido]'
        if (acc.find(c=> c.nivel === nivel)) {
          acc.find(c=> c.nivel === nivel).leads++
        }
        else {
          acc.push({ nivel, leads: 1 })
        }
        return acc
      }, [])
      .filter(c=> c.leads > Math.max(2, leads.length/20))
      .sort((a, b)=> b.leads - a.leads)
      .map(lead=> ({
        nivel: lead.nivel,
        total: lead.leads,
        porcentaje: `${(lead.leads/leads.length*100).toFixed(2)} %`
      }))
    }
  />
)

const AsignaturaTable = ({ leads })=> (
  <SmallTable
    details={[
      { title: 'Asignaturas de interés', key: 'asignatura', align: 'left' },
      { title: 'Leads', key: 'total', align: 'center' },
      { title: 'Leads (%)', key: 'porcentaje', align: 'center' }
    ]}
    data={(leads || [])
      .reduce((acc, lead)=> {
        (lead.asignaturas || []).forEach(asignatura=> {
          if (acc.find(c=> c.asignatura === asignatura)) {
            acc.find(c=> c.asignatura === asignatura).leads++
          }
          else {
            acc.push({ asignatura, leads: 1 })
          }
        })
        return acc
      }, [])
      .filter(c=> c.leads > Math.max(2, leads.length/20))
      .sort((a, b)=> b.leads - a.leads)
      .map(lead=> ({
        asignatura: lead.asignatura,
        total: lead.leads,
        porcentaje: `${(lead.leads/leads.length*100).toFixed(2)} %`
      }))
    }
  />
)

const CentroEstudiosTable = ({ leads })=> (
  <SmallTable
    details={[
      { title: 'Centro de estudios actual', key: 'centro', align: 'left' },
      { title: 'Leads', key: 'total', align: 'center' },
      { title: 'Leads (%)', key: 'porcentaje', align: 'center' }
    ]}
    data={(leads || [])
      .reduce((acc, lead)=> {
        const centro = lead.centroEstudios || '[DESCONOCIDO]'
        if (acc.find(c=> c.centro === centro)) {
          acc.find(c=> c.centro === centro).leads++
        }
        else {
          acc.push({ centro, leads: 1 })
        }
        return acc
      }, [])
      .filter(c=> c.leads > Math.max(2, leads.length/20))
      .sort((a, b)=> b.leads - a.leads)
      .map(lead=> ({
        centro: lead.centro,
        total: lead.leads,
        porcentaje: `${(lead.leads/leads.length*100).toFixed(2)} %`
      }))
    }
  />
)

const CaptacionTable = ({ leads })=> (
  <SmallTable
    details={[
      { title: 'Resultado', key: 'resultado', align: 'left' },
      { title: 'Clase de prueba', key: 'prueba', align: 'center' },
      { title: 'Leads', key: 'total', align: 'center' },
      { title: 'Leads (%)', key: 'porcentaje', align: 'center' }
    ]}
    data={(leads || [])
      .reduce((acc, lead)=> {
        if (lead.estado === 'CLIENTE_CAPTADO' && lead.prueba) acc[0].cantidad++
        else if (lead.estado === 'CLIENTE_CAPTADO' && !lead.prueba) acc[1].cantidad++
        else if (lead.estado === 'CLIENTE_PERDIDO' && lead.prueba) acc[2].cantidad++
        else if (lead.estado === 'CLIENTE_PERDIDO' && !lead.prueba) acc[3].cantidad++
        else if (lead.prueba) acc[4].cantidad++
        else acc[5].cantidad++
        return acc
      }, [
        { estado: 'Lead Captado', prueba: 'Si', cantidad: 0 },
        { estado: 'Lead Captado', prueba: 'No', cantidad: 0 },
        { estado: 'Lead Perdido', prueba: 'Si', cantidad: 0 },
        { estado: 'Lead Perdido', prueba: 'No', cantidad: 0 },
        { estado: 'Lead Sin Cerrar', prueba: 'Si', cantidad: 0 },
        { estado: 'Lead Sin Cerrar', prueba: 'No', cantidad: 0 }
      ])
      .map(captacion=> ({
        resultado: captacion.estado,
        prueba: captacion.prueba,
        total: captacion.cantidad,
        porcentaje: leads.length ? `${(captacion.cantidad/leads.length*100).toFixed(2)} %` : '--'
      }))
    }
  />
)

const CausaPerdidosTabla = ({ leads })=> (
  <SmallTable
    details={[
      { title: 'Causa de baja de los leads perdidos', key: 'baja', align: 'left' },
      { title: 'Leads', key: 'total', align: 'center' },
      { title: 'Leads (%)', key: 'porcentaje', align: 'center' }
    ]}
    data={(leads || [])
      .reduce((acc, lead)=> {
        const baja = lead.causaPerdido
        if (!baja) return acc
        if (acc.find(c=> c.baja === baja)) {
          acc.find(c=> c.baja === baja).leads++
        }
        else {
          acc.push({ baja, leads: 1 })
        }
        return acc
      }, [])
      .sort((a, b)=> b.leads - a.leads)
      .map(lead=> ({
        baja: lead.baja,
        total: lead.leads,
        porcentaje: `${(lead.leads/leads.length*100).toFixed(2)} %`
      }))
    }
  />
)

const Comportamiento = ()=> {

  const notification = useNotification()

  const [centro, setCentro] = useState(getAuth().centro)
  const [mes, setMes] = useState((new Date()).getMonth()+1)
  const [ejercicio, setEjercicio] = useState((new Date()).getFullYear())

  const { isLoading, data=[] } = useQuery({
    queryKey: ['feedback', 'list', mes, ejercicio, centro], 
    queryFn: ()=> {
      const fecha_inicio = `${mes}/${ejercicio}`
      const fecha_fin = `${(mes%12)+1}/${mes < 12 ? ejercicio : (ejercicio+1)}`
      return getLeadList({ fecha_inicio, fecha_fin, "centros[]": [centro] })
        .then(datos=> datos)
        .catch(err=> {
          notification.error({ title: 'Error al recuperar los leads', content: err })
          return []
        })
    }
  })

  const leads_validos = data.filter(l=> l.estado !== 'LEAD_NO_VALIDO')

  return (
    <PageLoading isLoading={isLoading}>
      <TableHeader
        actions={(
          <>
            <MesSelector
              className={css.month}
              name='mes'
              label='Mes'
              value={mes}
              onChange={e=> setMes(e.target.value)}
            />
            <EjercicioSelector
              className={css.year}
              name='ejercicio'
              label='Ejercicio'
              value={ejercicio}
              onChange={e=> setEjercicio(e.target.value)}
            />
            <AcademiaSelector
              name='academia'
              label='Academia'
              value={centro}
              showAll
              onChange={e=> setCentro(e.target.value)}
            />
          </>
        )}
        title={`Hay ${leads_validos.length} leads válidos (Sobre ${data.length} leads entrantes)`}
        showSearch={false}
      />
      <div className={css.section}>
        <CaptacionTable leads={leads_validos} />
      </div>
      <div className={css.section}>
        <ComoConoceTable leads={leads_validos} />
      </div>
      <div className={css.section}>
        <ObjetivosTable leads={leads_validos} />
      </div>
      <div className={css.section}>
        <NivelTable leads={leads_validos} />
      </div>
      <div className={css.section}>
        <AsignaturaTable leads={leads_validos} />
      </div>
      <div className={css.section}>
        <CentroEstudiosTable leads={leads_validos} />
      </div>
      <div className={css.section}>
        <CausaPerdidosTabla leads={leads_validos} />
      </div>
    </PageLoading>
  )

}

export default Comportamiento