
import { GoogleLogin } from '@react-oauth/google'
import { useNavigate } from 'react-router'
import { useNotification } from '../../context/NotificationManager/NotificationManager'
import { login } from '../../utils/api/login'
import { storeAuth } from '../../utils/auth'
import logo from './crm-black.png'
import css from './Login.module.css'

const Login = ()=> {

  const navigate = useNavigate()
  const notification = useNotification()

  const onSuccess = ({ credential })=> {
    login({ token: credential })
      .then((auth)=> {
        storeAuth(auth)
        notification.success({ title: 'Sesión iniciada', content: `Hola, ${auth.username}` })
        navigate('/')

      })
      .catch(err=> notification.error({ title: 'Error iniciando sesión', content: err }))
  }

  const onError = ()=> {
    notification.error({ 
      title: 'Error iniciando sesión',
      content: 'Ha habido un error verificando tus datos con Google'
    })
  }


  return (
    <main className={css.main}>
      <img className={css.logo} alt='CRM' src={logo} />
      <GoogleLogin
        text='Iniciar sesión'
        onSuccess={onSuccess}
        onFailure={onError}
      />
    </main>
  )
}

export default Login