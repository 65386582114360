import { useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material'
import { isBlank } from '../../utils/string'
import CausaPerdidoSelector from '../../components/form/CausaPerdidoSelector/CausaPerdidoSelector'
import css from './ModalContactoLeadCerrado.module.css'

const EstadoLeadPicker = ({ value, onChange }) => (
  <FormControl className={css.selector}>
    <RadioGroup
      row
      name='estado-lead'
      value={value}
      onChange={onChange}
      className={css.radioGroup}
    >
      <FormControlLabel value='CLIENTE_PERDIDO' control={<Radio />} label='Lead perdido' />
      <FormControlLabel value='LEAD_NO_VALIDO' control={<Radio />} label='Lead no válido' />
    </RadioGroup>
  </FormControl>
)

const ModalContactoLeadCerrado = ({ open, disabled, onSubmit, onClose }) => {
  const [estadoLead, setEstadoLead] = useState('')

  const [lead, setLead] = useState({
    alumno: {},
    causa_perdido: '',
    observaciones: ''
  })

  useEffect(() => {
    setLead({
      alumno: {},
      causa_perdido: '',
      observaciones: ''
    })
    setEstadoLead('')
  }, [open])

  useEffect(() => {
    setLead(current => ({
      alumno: {},
      causa_perdido: '',
      observaciones: current.observaciones
    }))
  }, [estadoLead])

  const isSubmitDisabled =
    (estadoLead === 'CLIENTE_PERDIDO' && isBlank(lead.causa_perdido))

  const handleSubmit = () => {
    const data = {
      lead_status: estadoLead,
      observaciones: lead.observaciones
    }
    if (estadoLead === 'CLIENTE_PERDIDO') data.causa_perdido = lead.causa_perdido
    onSubmit(data)
  }

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth='md'>
      <DialogTitle>Datos del lead cerrado</DialogTitle>
      <DialogContent className={css.main}>
        <DialogContentText className={css.intro}>
          Rellena los datos necesarios para marcar el lead como cerrado
        </DialogContentText>
        <EstadoLeadPicker
          value={estadoLead}
          onChange={e => setEstadoLead(e.target.value)}
        />
        {estadoLead === 'CLIENTE_PERDIDO' && (
          <CausaPerdidoSelector
            className={css.input}
            name='causa-perdido'
            label='Motivo de cierre'
            value={lead.causa_perdido}
            onChange={e => setLead({ ...lead, causa_perdido: e.target.value })}
          />
        )}
        {estadoLead && (
          <TextField
            className={css.input}
            label='Observaciones'
            multiline
            value={lead.observaciones}
            onChange={e => setLead({ ...lead, observaciones: e.target.value })}
            variant='standard'
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button 
          color='error' 
          disabled={disabled}
          onClick={onClose}
        >
          Cancelar
        </Button>
        <Button
          disabled={isSubmitDisabled || disabled}
          color='primary'
          onClick={handleSubmit}
        >
          Cerrar lead
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalContactoLeadCerrado
