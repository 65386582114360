import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { Button, Card, CardContent, TextField, Typography } from '@mui/material'
import { useNotification } from '../../context/NotificationManager/NotificationManager'
import CodigoPromocionalCentroSelector from '../../components/form/CodigoPromocionalCentroSelector/CodigoPromocionalCentroSelector'
import TutorSelector from '../../components/form/TutorSelector/TutorSelector'
import FechaSelector from '../../components/form/FechaSelector/FechaSelector'
import { getAuth } from '../../utils/auth'
import { getBienvenidaAlumno, getCondicionesContratacionURL } from '../../utils/documents'
import { validarAlumno } from '../../utils/model/alumnos'
import { dateToString } from '../../utils/date'
import { createAlumno } from '../../utils/api/alumnos'
import LeadSelector from '../../components/form/LeadSelector/LeadSelector'
import AcademiaSelector from '../../components/form/AcademiaSelector/AcademiaSelector'
import css from './NuevoAlumno.module.css'

const DatosAlumno = ({ alumno, onUpdate }) => (
  <Card className={css.card}>
    <CardContent className={css.cardContent}>
      <Typography variant='h6' className={css.cardTitle}>
        Datos del alumno
      </Typography>
      <TextField
        name='nombre'
        label='Nombre'
        variant='standard'
        margin='dense'
        className={css.input}
        value={alumno.nombre}
        onChange={e => onUpdate('nombre', e.target.value)}
        fullWidth
        required
      />
      <TextField
        name='apellidos'
        label='Apellidos'
        variant='standard'
        margin='dense'
        className={css.input}
        value={alumno.apellidos}
        onChange={e => onUpdate('apellidos', e.target.value)}
        fullWidth
        required
      />
      <FechaSelector
        className={css.input}
        name='fecha_nacimiento'
        label='Fecha de nacimiento'
        value={alumno.fechaNacimiento}
        onChange={value=> onUpdate('fechaNacimiento', value)}
      />
      <TextField
        name='dni'
        label='DNI'
        variant='standard'
        margin='dense'
        className={css.input}
        value={alumno.dni}
        onChange={e => onUpdate('dni', e.target.value)}
        fullWidth
      />
      <TextField
        name='telefono'
        label='Teléfono fijo'
        variant='standard'
        margin='dense'
        type='number'
        className={css.input}
        value={alumno.telefono}
        onChange={e => onUpdate('telefono', e.target.value)}
        fullWidth
      />
      <TextField
        name='movil'
        label='Teléfono móvil'
        variant='standard'
        margin='dense'
        type='number'
        className={css.input}
        value={alumno.movil}
        onChange={e => onUpdate('movil', e.target.value)}
        fullWidth
        required
      />
      <TextField
        name='email'
        label='Email'
        variant='standard'
        margin='dense'
        className={css.input}
        value={alumno.email}
        onChange={e => onUpdate('email', e.target.value)}
        fullWidth
      />
      <TextField
        name='codigo_postal'
        label='Código postal'
        variant='standard'
        margin='dense'
        type='number'
        className={css.input}
        value={alumno.codigoPostal}
        onChange={e => onUpdate('codigoPostal', e.target.value)}
        fullWidth
      />
    </CardContent>
  </Card>
)

const DatosTutor = ({ title, index, alumno, onUpdate }) => (
  <Card variant='outlined' className={css.card}>
    <CardContent className={css.cardContent}>
      <Typography variant='h6' className={css.cardTitle}>
        {title}
      </Typography>
      <TutorSelector
        name='rol'
        className={css.input}
        value={alumno[`tutor${index}Rol`]}
        onChange={(_e, value) => onUpdate(`tutor${index}Rol`, value)}
        fullWidth
      />
      <TextField
        name='nombre'
        label='Nombre'
        variant='standard'
        margin='dense'
        className={css.input}
        value={alumno[`tutor${index}Nombre`]}
        onChange={e => onUpdate(`tutor${index}Nombre`, e.target.value)}
        fullWidth
      />
      <TextField
        name='email'
        label='Email'
        variant='standard'
        margin='dense'
        className={css.input}
        value={alumno[`tutor${index}Email`]}
        onChange={e => onUpdate(`tutor${index}Email`, e.target.value)}
        fullWidth
      />
      <TextField
        name='movil'
        label='Teléfono móvil'
        variant='standard'
        margin='dense'
        type='number'
        className={css.input}
        value={alumno[`tutor${index}Movil`]}
        onChange={e => onUpdate(`tutor${index}Movil`, e.target.value)}
        fullWidth
      />
      <TextField
        name='dni'
        label='DNI'
        variant='standard'
        margin='dense'
        className={css.input}
        value={alumno[`tutor${index}Dni`]}
        onChange={e => onUpdate(`tutor${index}Dni`, e.target.value)}
        fullWidth
      />
    </CardContent>
  </Card>
)

const DatosFacturacion = ({ alumno, onUpdate }) => (
  <Card className={css.card}>
    <CardContent className={css.cardContent}>
      <Typography variant='h6' className={css.cardTitle}>
        Datos de facturacion
      </Typography>
      <TextField
        name='facturacionNombre'
        label='Titular de la factura'
        variant='standard'
        margin='dense'
        className={css.input}
        value={alumno.facturacionNombre}
        onChange={e => onUpdate('facturacionNombre', e.target.value)}
        fullWidth
      />
      <TextField
        name='facturacionIdentificacion'
        label='Identificación de la factura (CIF / NIF)'
        variant='standard'
        margin='dense'
        className={css.input}
        value={alumno.facturacionIdentificacion}
        onChange={e => onUpdate('facturacionIdentificacion', e.target.value)}
        fullWidth
      />
    </CardContent>
  </Card>
)

const DatosCaptacion = ({ alumno, onUpdate }) => (
  <Card className={css.card}>
    <CardContent className={css.cardContent}>
      <Typography variant='h6' className={css.cardTitle}>
        Datos captación
      </Typography>
      <AcademiaSelector
        className={css.input}
        name='academia'
        label='Academia'
        value={alumno.centro}
        onChange={e => onUpdate('centro', e.target.value)}
      />
      <LeadSelector
        className={css.input}
        name='lead'
        label='Lead asociado'
        value={alumno.lead}
        onChange={value=> onUpdate('lead', value)}
      />
      <CodigoPromocionalCentroSelector
        name='codigo_promocional'
        label='Código promocional de bienvenida'
        className={css.input}
        value={alumno.codigoPromocional}
        onChange={e => onUpdate('codigoPromocional', e.target.value)}
      />
      <TextField
        name='observaciones'
        label='Observaciones'
        variant='standard'
        margin='dense'
        value={alumno.observaciones}
        className={css.input}
        onChange={e => onUpdate('observaciones', e.target.value)}
        maxRows={8}
        multiline
        fullWidth
      />
    </CardContent>
  </Card>
)

const NuevoAlumno = ()=> {

  const navigate = useNavigate()
  const notification = useNotification()

  const [alumno, setAlumno] = useState({
    nombre: '',
    apellidos: '',
    fechaNacimiento: null,
    dni: '',
    telefono: '',
    movil: '',
    email: '',
    codigoPostal: '',

    tutor1Rol: '',
    tutor1Nombre: '',
    tutor1Email: '',
    tutor1Movil: '',
    tutor1Dni: '',

    tutor2Rol: '',
    tutor2Nombre: '',
    tutor2Email: '',
    tutor2Movil: '',
    tutor2Dni: '',

    facturacionNombre: '',
    facturacionIdentificacion: '',

    centro: '',
    lead: '',
    codigoPromocional: '',
    observaciones: '',
  })

  const { isPending: isCreatingAlumno, mutate: crearAlumno } = useMutation({
    mutationFn: createAlumno,
    onSuccess: (nuevoAlumno)=> {
      notification.success({ title: 'Alumno registrado', content: 'Se ha creado la ficha del alumno correctamente' })
      const authData = getAuth()
      // Documento bienvenida      
      const urlBienvenida = getBienvenidaAlumno({ alumno: nuevoAlumno.nombre.split(' ')[0], academia: authData.centro })
      window.open(urlBienvenida,'_blank')
      // Documento condiciones
      const urlCondiciones = getCondicionesContratacionURL({ alumno: nuevoAlumno.nombre, academia: authData.centro })
      window.open(urlCondiciones, '_blank')
      // Redirigir a ficha alumno
      navigate(`/alumnos/${nuevoAlumno.id}/ficha`)
    },
    onError: (e)=> {
      notification.error({ title: 'Error generando ficha', content: e })
    }
  })

  const handleSubmit = () => {
    const isValid = validarAlumno(alumno)

    if (isValid.status === 'error') {
      notification.warning({ title: 'Faltan datos', content: isValid.errors[0] })
      return
    }
    crearAlumno({
      nombre: alumno.nombre,
			apellidos: alumno.apellidos,
			fecha_nacimiento: dateToString(alumno.fechaNacimiento),
			dni: alumno.dni,
			email: alumno.email,
			telefono: alumno.telefono,
			movil: alumno.movil,
			codigo_postal: alumno.codigoPostal,
			tutor_1_rol: alumno.tutor1Rol,
			tutor_1_nombre: alumno.tutor1Nombre,
			tutor_1_email: alumno.tutor1Email,
			tutor_1_movil: alumno.tutor1Movil,
			tutor_1_DNI: alumno.tutor1Dni,
			tutor_2_rol: alumno.tutor2Rol,
			tutor_2_nombre: alumno.tutor2Nombre,
			tutor_2_email: alumno.tutor2Email,
			tutor_2_movil: alumno.tutor2Movil,
			tutor_2_DNI: alumno.tutor2Dni,
      facturacion_nombre: alumno.facturacionNombre,
      facturacion_identificacion: alumno.facturacionIdentificacion,
			observaciones: alumno.observaciones,
			codigo_promocional: alumno.codigoPromocional,
      centro: alumno.centro,
    })
  }

  const handleFieldUpdate = (field, value)=> {
    setAlumno(currentAlumno=> ({
      ...currentAlumno,
      [field]: value
    }))
  }

  return (
    <div className={css.main}>
      <Typography variant='h5' className={css.title}>
        Registrar nuevo alumno
      </Typography>
      <div className={css.content}>
        <DatosAlumno alumno={alumno} onUpdate={handleFieldUpdate} />
        <DatosTutor title='Primer tutor' index={1} alumno={alumno} onUpdate={handleFieldUpdate} />
        <DatosTutor title='Segundo tutor' index={2} alumno={alumno} onUpdate={handleFieldUpdate} />
        <DatosFacturacion alumno={alumno} onUpdate={handleFieldUpdate} />
        <DatosCaptacion alumno={alumno} onUpdate={handleFieldUpdate} />
        <div className={css.submit}>
          <Button
            variant='contained'
            className={css.button}
            endIcon={<i className='material-icons'>save</i>}
            onClick={handleSubmit}
            disabled={isCreatingAlumno}
          >
            Registrar alumno
          </Button>
        </div>
      </div>
    </div>
  )
}

export default NuevoAlumno
