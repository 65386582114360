import { useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material'
import { isAnyBlank } from '../../utils/string'
import css from './ModalNuevoHelpdesk.module.css'

const ModalNuevoHelpdesk = ({ open, onSubmit, onClose }) => {

  const [helpdesk, setHelpdesk] = useState({
    titulo: '',
    descripcion: ''
  })

  useEffect(() => {
    if (open) setHelpdesk({ titulo: '', descripcion: '' })
  }, [open])

  const isSubmitDisabled = isAnyBlank(helpdesk.titulo, helpdesk.descripcion)

  const handleSubmit = () => {
    onSubmit({
      titulo: helpdesk.titulo,
      descripcion: helpdesk.descripcion,
      categoria: 'CRM'
    })
  }

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth='md'>
      <DialogTitle>Nuevo helpdesk</DialogTitle>
      <DialogContent className={css.main}>
        <DialogContentText className={css.intro}>
          Utiliza este formulario para reportar cualquier incidencia con la
          plataforma.
        </DialogContentText>
        <TextField
          className={css.input}
          label='Resumen'
          value={helpdesk.titulo}
          onChange={e => setHelpdesk({ ...helpdesk, titulo: e.target.value })}
          variant='standard'
        />
        <TextField
          className={css.input}
          label='Descripción completa'
          multiline
          value={helpdesk.descripcion}
          onChange={e => setHelpdesk({ ...helpdesk, descripcion: e.target.value })}
          variant='standard'
        />
      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={onClose}>
          Cancelar
        </Button>
        <Button
          disabled={isSubmitDisabled}
          color='primary'
          onClick={handleSubmit}
        >
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalNuevoHelpdesk
