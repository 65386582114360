import { useState } from "react"
import { Card, CardContent, Link, SpeedDial, SpeedDialAction, SpeedDialIcon, Typography } from "@mui/material"
import LeadHeader from "../../../components/LeadHeader/LeadHeader"
import PageLoading from "../../../components/PageLoading/PageLoading"
import useLead from "../../../hooks/useLead"
import { capitalize } from "../../../utils/string"
import ModalContactoLead from "../../../modals/ModalContactoLead/ModalContactoLead"
import ModalContactoPrueba from "../../../modals/ModalContactoPrueba/ModalContactoPrueba"
import ModalContactoLeadCualificado from "../../../modals/ModalContactoLeadCualificado/ModalContactoLeadCualificado"
import ModalContactoLeadCerrado from "../../../modals/ModalContactoLeadCerrado/ModalContactoLeadCerrado"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { addContacto, modificarPrueba } from "../../../utils/api/leads"
import { useNotification } from "../../../context/NotificationManager/NotificationManager"
import ModalEditarPrueba from "../../../modals/ModalEditarPrueba/ModalEditarPrueba"
import css from './Contactos.module.css'

const NUEVO_CONTACTO_OPTIONS = [
  { name: 'Cerrar', icon: <i className='material-icons'>handshake</i> },
  { name: 'Presupuesto', icon: <i className='material-icons'>verified</i> },
  { name: 'Prueba', icon: <i className='material-icons'>emoji_objects</i> },
  { name: 'Lead', icon: <i className='material-icons'>support_agent</i> },
]

const PLANTILLA_MENSAJE_OPTIONS = [
  {
    name: "Cierre",
    icon: <i className="material-icons">handshake</i>,
    visible: () => true,
    message: () =>
      `Hola! Para cualquier cosa, estamos a su disposición.\n\nUn saludo, gracias.`,
  },
  {
    name: "Presupuesto",
    icon: <i className="material-icons">thumb_up</i>,
    visible: () => true,
    message: () =>
      `Hola, ¿qué tal? Le escribo para conocer si ya habían tomado una decisión respecto a la propuesta que les dimos.\n\nUn saludo, gracias!`,
  },
  {
    name: "Prueba",
    icon: <i className="material-icons">event_note</i>,
    visible: ({ prueba }) => !!prueba,
    message: ({ fecha, hora, centro }) =>
      `Hola, ¿qué tal? Le escribo para confirmar la clase de prueba gratuita que tenemos programada para el día ${fecha} a las ${hora} en la academia de ${centro}.\n\n¡Un saludo, gracias!`,
  },
  {
    name: "Seguimiento",
    icon: <i className="material-icons">update</i>,
    visible: () => true,
    message: () =>
      `Hola, ¿qué tal? Le escribo para conocer si seguían interesados en nuestros servicios.\n\nUn saludo, gracias.`,
  },
  {
    name: "Inicio",
    icon: <i className="material-icons">person_search</i>,
    visible: () => true,
    message: ({ nombre }) =>
      `Hola ${nombre}! ¿qué tal? Le escribo de mundoestudiante en relación a un formulario web que rellenó solicitando información. Cuéntenos, ¿en qué podemos ayudarle?\n\n¡Un saludo!`,
  },
]

const getMensajeWhatsapp = ({ lead, mensaje }) => {
  if (mensaje.name === 'Inicio') {
    const nombre = lead.nombreContacto || "futuro alumno"
    return mensaje.message({ nombre })
  }
  if (mensaje.name === 'Prueba') {
    const prueba = lead.contactos.findLast(contacto => contacto.estadoFeedback === "PRUEBA")
    return mensaje.message({ fecha: prueba.proximoContacto, hora: prueba.hora, centro: prueba.centroPrueba })
  }
  return mensaje.message()
}

const getContactIcon = (formaContacto) => {
  switch (formaContacto) {
    case 'llamada':
    case 'telefono': return 'call'
    case 'email':
    case 'e-mail': return 'forward_to_inbox'
    case 'persona':
    case 'tienda': return 'partner_exchange'
    case 'web': return 'screen_share'
    case 'whatsapp': return 'forum'
    default: return 'sync_alt'
  }
}

const getPruebaIcon = (asistencia)=> {
  if (asistencia === 'VIENE') return 'check_circle'
  else if (asistencia === 'FALTA_JUSTIFICADA') return 'highlight_off'
  else if (asistencia === 'FALTA') return 'close'
  else return 'help_outline'
}

const showProximosPasos = (estadoFeedback)=> {
  [ 'PRUEBA', 'CLIENTE_CAPTADO', 'CLIENTE_PERDIDO', 'LEAD_NOVALIDO' ].includes(estadoFeedback)
}

const LeadCard = ({ contacto, index, onEdit }) => (
  <Card className={css.card}>
    <CardContent>
      <div className={css.header}>
        <Typography variant='h6'>
          {contacto.estadoFeedback.replace(/_/g, ' ')}
        </Typography>
        <div className={css.index}>{index + 1}</div>
      </div>
      <Typography className={css.detailGroup}>
        Detalles contacto
      </Typography>
      <Typography className={css.detail} variant='body2' component='p'>
        <i className="material-icons">event</i> {contacto.fechaContacto}
      </Typography>
      <Typography className={css.detail} variant='body2' component='p'>
        <i className="material-icons">face</i> {contacto.usuarioContacto}
      </Typography>
      {contacto.centroContacto && (
        <Typography className={css.detail} variant='body2' component='p'>
          <i className="material-icons">school</i> {contacto.centroContacto}
        </Typography>
      )}
      {contacto.formaContacto && (
        <Typography className={css.detail} variant='body2' component='p'>
          <i className="material-icons">{getContactIcon(contacto.formaContacto)}</i>{' '}
          {capitalize(contacto.formaContacto)} 
        </Typography>
      )}
      {contacto.estadoFeedback === 'PRUEBA' && (
        <>
          <Typography className={css.detailGroup}>
            Detalles prueba 
            <Link className={css.detailEdit} component="button" variant="body2" onClick={onEdit}>
              Modificar detalles
            </Link>
          </Typography>
          <Typography className={css.detail} variant='body2' component='p'>
            <i className="material-icons">event</i> {contacto.proximoContacto} → {contacto.hora}
          </Typography>
          <Typography className={css.detail} variant='body2' component='p'>
            <i className="material-icons">school</i> {contacto.centroPrueba}
          </Typography>
          <Typography className={css.detail} variant='body2' component='p'>
            <i className="material-icons">emoji_people</i> {contacto.profesor || 'Sin profesor'}
          </Typography>
          <Typography className={css.detail} variant='body2' component='p'>
            <i className="material-icons">lightbulb_circle</i> {contacto.asignatura}
          </Typography>
          <Typography className={css.detail} variant='body2' component='p'  data-confirmada={contacto.confirmada ? 'yes' : 'no'}>
            <i className="material-icons">{contacto.confirmada ? 'check_circle' : 'psychology_alt'}</i> 
            {contacto.confirmada ? 'Prueba confirmada' : 'Pendiente de confirmar'}
          </Typography>
          <Typography className={css.detail} variant='body2' component='p' data-asistencia={contacto.asistencia}>
            <i className="material-icons">{getPruebaIcon(contacto.asistencia)}</i>
            {contacto.asistencia.replace(/_/g, ' ')}
          </Typography>
        </> 
      )}
      {contacto.valoracion && (
        <>
          <Typography className={css.detailGroup}>
            Valoracion prueba
          </Typography>
          <blockquote className={css.blockInfo}>
            {contacto.valoracion}
          </blockquote>
        </>
      )}
      {contacto.presupuesto && (
        <>
          <Typography className={css.detailGroup}>
            Presupuesto ofrecido
          </Typography>
          <blockquote className={css.blockInfo}>
            {contacto.presupuesto}
          </blockquote>
        </>
      )}
      {contacto.alumnoId && (
        <>
          <Typography className={css.detailGroup}>
            Ficha de alumno
          </Typography>
          <Typography className={css.detail} variant='body2' component='p'>
            <i className="material-icons">person_outline</i>
            <Link className={css.link} href={`/alumnos/${contacto.alumnoId}/ficha`}>
              {contacto.alumno}
            </Link>
          </Typography> 
        </>
      )}
      {contacto.causa && (
        <>
          <Typography className={css.detailGroup}>
            Motivo de cierre
          </Typography>
          <Typography className={css.detail} variant='body2' component='p' data-confirmada='no'>
            <i className="material-icons">close</i>
            {contacto.causa}
          </Typography>
        </>
      )}
      {contacto.observaciones && (
        <>
          <Typography className={css.detailGroup}>
            Observaciones
          </Typography>
          <blockquote className={css.blockInfo}>
            {contacto.observaciones}
          </blockquote>
        </>
      )}
      {showProximosPasos(contacto.estadoFeedback) && (
        <>
          <Typography className={css.detailGroup}>
            Próximos pasos
          </Typography>
          <Typography className={css.detail} variant='body2' component='p'>
            <i className="material-icons">event</i> {contacto.proximoContacto}
          </Typography>
          {contacto.fechaIncorporacion && (
            <Typography className={css.detail} variant='body2' component='p'>
              <i className="material-icons">event</i> {contacto.fechaIncorporacion} (Fecha estimada incorporación)
            </Typography>
          )}
        </>
      )}
    </CardContent>
  </Card>
)

const Contactos = () => {

  const notification = useNotification()
  const queryClient = useQueryClient()

  const { isLoading, lead } = useLead()

  const [speedDialOpen, setSpeedDialOpen] = useState(false)
  const [showMessageSpeedDial, setShowMessageSpeedDial] = useState(false)

  const [nuevoContacto, setNuevoContacto] = useState(null)

  const [pruebaEdit, setPruebaEdit] = useState(null)

  const { isPending: isLeadUpdating, mutate: updateLead } = useMutation({
    mutationFn: addContacto,
    onSuccess: () => {
      notification.success({ title: 'Lead actualizado', content: 'Contacto añadido correctamente' })
      queryClient.invalidateQueries({
        queryKey: ['marketing', 'feedback', 'list', 'CRM']
      })
      queryClient.invalidateQueries({
        queryKey: ['marketing', 'lead', lead.id]
      })
      setNuevoContacto(null)
      setSpeedDialOpen(false)
    },
    onError: err => {
      notification.error({ title: 'Error actualizando el lead', content: err })
    },
  })

  const { isPending: isPruebaUpdating, mutate: updatePrueba } = useMutation({
    mutationFn: modificarPrueba,
    onSuccess: () => {
      notification.success({ title: 'Prueba actualizada', content: 'Prueba modificada correctamente' })
      queryClient.invalidateQueries({
        queryKey: ['marketing', 'feedback', 'list', 'CRM']
      })
      queryClient.invalidateQueries({
        queryKey: ['marketing', 'lead', lead.id]
      })
      setPruebaEdit(null)
    },
    onError: err => {
      notification.error({ title: 'Error actualizando la prueba', content: err })
    },
  })
  
  const { contactos=[] } = lead

  const handleActionClick = (action) => {
    setNuevoContacto(action)
    setSpeedDialOpen(false)
  }

  const handleNuevoContacto = datos=> {
    updateLead({ id: lead.id, ...datos })
  }

  const handleEditClick = (contacto)=> setPruebaEdit(contacto)

  const handlePruebaEdit = (datosPrueba)=> {
    if (isPruebaUpdating) return
    updatePrueba({ id: lead.id, ...datosPrueba })
  }

  const handleSendWhatsAppMessage = (mensaje) => {
    const message = getMensajeWhatsapp({ lead, mensaje })
    const numeroWhatsApp = lead.telefonoContacto || lead.telefonoAlumno
    if (!numeroWhatsApp) {
      notification.error({ title: 'Error enviando mensaje', content: 'El lead no tiene teléfono de contacto' })
      return
    }
    const encodedMessage = encodeURIComponent(message)
    const whatsappURL = `https://wa.me/${numeroWhatsApp}?text=${encodedMessage}`
    window.open(whatsappURL, '_blank')
  }

  const isWhatsappEnabled = !!lead.telefonoContacto
  const showPrueba = contactos.some((contacto) => contacto.estadoFeedback === "PRUEBA")
  
  return (
    <PageLoading isLoading={isLoading}>
      <div className={css.main}>
        <LeadHeader label='Contactos' />
        <div className={css.content}>
          {contactos.map((contacto, index) => (
            <LeadCard 
              key={index} 
              contacto={contacto} 
              onEdit={()=> handleEditClick(contacto)}
              index={index} 
            />
          ))}
        </div>
      </div>
      {isWhatsappEnabled && (
        <SpeedDial
          className={css.dialMenu}
          ariaLabel="Opciones de Mensaje"
          icon={<SpeedDialIcon icon={<><i className='material-icons'>chat</i></>} />}
          onClose={() => setShowMessageSpeedDial(false)}
          onOpen={() => setShowMessageSpeedDial(true)}
          open={showMessageSpeedDial}
        >
          {PLANTILLA_MENSAJE_OPTIONS
            .filter(option=> option.visible({ prueba: showPrueba }))
            .map((action) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                tooltipOpen
                onClick={() => handleSendWhatsAppMessage(action)}
              />
            ))}
        </SpeedDial>
      )}
      <SpeedDial
        className={css.speed}
        ariaLabel="Nuevo contacto"
        icon={<SpeedDialIcon />}
        onClose={()=> setSpeedDialOpen(false)}
        onOpen={()=> setSpeedDialOpen(true)}
        open={speedDialOpen}
      >
        {NUEVO_CONTACTO_OPTIONS.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={()=> handleActionClick(action.name)}
          />
        ))}
      </SpeedDial>
      <ModalContactoLead
        open={nuevoContacto === 'Lead'}
        disabled={isLeadUpdating}
        onClose={()=> setNuevoContacto(null)}
        onSubmit={handleNuevoContacto}
      />
      <ModalContactoPrueba
        open={nuevoContacto === 'Prueba'}
        disabled={isLeadUpdating}
        onClose={()=> setNuevoContacto(null)}
        onSubmit={handleNuevoContacto}
      />
      <ModalContactoLeadCualificado
        open={nuevoContacto === 'Presupuesto'}
        disabled={isLeadUpdating}
        onClose={()=> setNuevoContacto(null)}
        onSubmit={handleNuevoContacto}
      />
      <ModalContactoLeadCerrado
        open={nuevoContacto === 'Cerrar'}
        disabled={isLeadUpdating}
        onClose={()=> setNuevoContacto(null)}
        onSubmit={handleNuevoContacto}
      />
      <ModalEditarPrueba
        open={!!pruebaEdit}
        pruebaAlumno={pruebaEdit}
        disabled={isPruebaUpdating}
        onClose={()=> setPruebaEdit(null)}
        onSubmit={handlePruebaEdit}
      />
    </PageLoading>
  )
}

export default Contactos