import { useState } from "react"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  IconButton,
  Typography,
} from "@mui/material"
import css from "./DatosSeguridad.module.css"

const DatosSeguridad = ({ accesoLead, onEdit }) => {

  const [isExpanded, setExpanded] = useState(true)

  const handleChange = (_event, isExpanded) => setExpanded(isExpanded)
  
  const handleEdit = (e)=> {
    e.stopPropagation()
    onEdit()
  }

  const hasPassword = accesoLead && accesoLead.password
  
  return (
    <Accordion expanded={isExpanded} onChange={handleChange}>
      <AccordionSummary
        expandIcon={<i className="material-icons">expand_more</i>}
      >
        <div className={css.label}>
          <Typography className={css.title} variant="h2">
            Seguridad
          </Typography>
          {isExpanded && (
            <IconButton
              className={css.button} 
              size="small" 
              color="secondary"
              onClick={handleEdit}
            >
              <i className="material-icons">edit</i>
            </IconButton>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {hasPassword ? (
          <Alert 
            icon={<i className='material-icons'>check</i>} 
            className={css.alert} 
            severity='info'
          >
            El lead tiene configurada una contraseña.
          </Alert>  
      ) : (
         <Alert 
          icon={<i className='material-icons'>clear</i>} 
          className={css.alert} 
          severity='error'
        >
         El lead no tiene configurada una contraseña.
        </Alert>
      )}
      </AccordionDetails>
    </Accordion>
  )
}

export default DatosSeguridad
