import { Card, CardContent, CardHeader, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useNotification } from '../../../../context/NotificationManager/NotificationManager'
import { getNotasAlumnoList } from '../../../../utils/api/alumnos'
import { dateToStringLong, dateToTimeString } from '../../../../utils/date'
import PageLoading from '../../../../components/PageLoading/PageLoading'
import css from './NotasAlumno.module.css'

const NotasAlumno = ({ alumnoId })=> {

  const notification = useNotification()

  const { isLoading, data: notasAlumnoList = [] } = useQuery({
    queryKey: ['alumnos', 'notas-alumno', 'list', alumnoId], 
    queryFn: () => getNotasAlumnoList(alumnoId)
      .then(datos=> datos.reverse())
      .catch(err=> {
        notification.error({ title: 'Error obteniendo notas de alumno', content: err })
        return []
      })
  })

  return (
    <PageLoading isLoading={isLoading}>
      {notasAlumnoList.length === 0 && (
        <Typography className={css.placeholder} variant='h6'>
          Este alumno no tiene ninguna nota en su ficha
        </Typography>
      )}
      {notasAlumnoList.map((nota) => (
        <div className={css.card} key={nota.id}>
          <Card>
            <CardHeader
              className={css.header}
              title={`${dateToStringLong(nota.fecha)} - ${dateToTimeString(nota.fecha)}`}
              titleTypographyProps={{ variant: 'body1' }}
              subheader={`${nota.usuario} escribió:`} 
            />
            <CardContent>
              <Typography variant='body1'>
                {nota.nota}
              </Typography>
            </CardContent>
          </Card>
        </div>
      ))}
    </PageLoading>
  )
}

export default NotasAlumno
