import { useEffect, useState } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material"
import FormaContactoSelector from "../../components/form/FormaContactoSelector/FormaContactoSelector"
import ObjetivoAlumnoSelector from "../../components/form/ObjetivoAlumnoSelector/ObjetivoAlumnoSelector"
import ComoConoceSelector from "../../components/form/ComoConoceSelector/ComoConoceSelector"
import NumberInput from "../../components/form/NumberInput/NumberInput"
import AcademiaSelector from "../../components/form/AcademiaSelector/AcademiaSelector"
import css from './ModalEditarDatosMarketingLead.module.css'

const ModalEditarDatosMarketingLead = ({ open, lead, onSubmit, onClose }) => {

  const [datos, setDatos] = useState({
    centro: '',
    codigoPostal: '',
    comoConoce: '',
    objetivos: '',
    formaContacto: '',
    telefonoEntrante: '',
  })

  useEffect(()=> {
    setDatos({
      centro: lead.centro,
      codigoPostal: lead.codigoPostal,
      comoConoce: lead.comoConoce,
      objetivos: lead.objetivos,
      formaContacto: lead.formaContacto,
      telefonoEntrante: lead.telefonoEntrante,
    })
  }, [open, lead])

  const handleChange = (name, value) => {
    setDatos(currentDatos => ({
      ...currentDatos,
      [name]: value
    }))
  }

  const handleSubmit = () => {
    onSubmit({ id: lead.id, ...datos })
  }

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth='md'>
      <DialogTitle>Editar lead</DialogTitle>
      <DialogContent className={css.main}>
        <DialogContentText className={css.intro}>
          Modifica los datos de marketing de este lead
        </DialogContentText>
        <section className={css.section}>
          <AcademiaSelector
            className={css.input}
            name='academia'
            label='Academia'
            value={datos.centro}
            onChange={e => handleChange('centro', e.target.value)}
          />
          <ComoConoceSelector
            className={css.input}
            name='comoConoce'
            label='¿Cómo nos ha conocido?'
            value={datos.comoConoce}
            onChange={e => handleChange('comoConoce', e.target.value)}
          />
          <FormaContactoSelector
            className={css.input}
            name='formaContacto'
            label='Forma de contacto'
            value={datos.formaContacto}
            onChange={e => handleChange('formaContacto', e.target.value)}
          />
          <ObjetivoAlumnoSelector
            className={css.input}
            name='objetivo'
            label='Objetivo del lead'
            value={datos.objetivos}
            onChange={e => handleChange('objetivos', e.target.value)}
          />
          <TextField
            className={css.input}
            label='Teléfono desde el que llama'
            value={datos.telefonoEntrante}
            onChange={e => handleChange('telefonoEntrante', e.target.value)}
            variant='standard'
          />
          <NumberInput
            className={css.input}
            label='Código postal'
            value={datos.codigoPostal}
            onChange={value=> handleChange('codigoPostal', value)}
          />
        </section>
      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={onClose}>Cancelar</Button>
        <Button color='primary' onClick={handleSubmit}>Actualizar</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalEditarDatosMarketingLead