
import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { dateFromString, dateToMonth, dateToString, forwardDays, forwardMonths, getStartOfMonth, isSameMonth, monthToString } from '../../../utils/date'
import { useNotification } from '../../../context/NotificationManager/NotificationManager'
import { IconButton } from '@mui/material'
import Calendar from '../../../components/Calendar/Calendar'
import { getClasesFechas } from '../../../utils/api/clases'
import { mergeClases } from '../../../utils/model/horario'
import TableHeader from '../../../components/TableHeader/TableHeader'
import AcademiaSelector from '../../../components/form/AcademiaSelector/AcademiaSelector'
import css from './Profesores.module.css'

const ClaseCalendario = ({ hora, profesor, dimmed })=> (
  <div className={css.calendarEvent} data-status={dimmed ? 'dimmed' : 'normal'}>
    <p className={css.calendarEventRow}>
      <i className='material-icons'>person</i> {profesor}
    </p>
    <p className={css.calendarEventRow}>
      <i className='material-icons'>schedule</i> {hora}
    </p>
  </div>
)

const Profesores = ()=> {

  const notification = useNotification()

  const [centro, setCentro] = useState('')
  const [mes, setMes] = useState(getStartOfMonth())

  const handleMonthChange = (offset) => {
    const newMes = new Date(mes)
    newMes.setMonth(newMes.getMonth() + offset)
    setMes(newMes)
  }

const { data: clasesList=[] } = useQuery({
  queryKey: ['planificacion', 'clases-profesor', 'list', monthToString(mes), centro], 
  enabled: !!centro,
  queryFn: ()=> getClasesFechas({
    fechaInicio: dateToString(forwardDays(mes, -6)),
    fechaFin: dateToString(forwardDays(forwardMonths(mes, 1), 5)),
    centro
  })
    .then(datos=> datos.map(clase=> ({
      ...clase,
      fecha: dateToString(clase.fecha)
    })))
    .catch(err=> {
      notification.error({ title: 'Error al recuperar las clases', content: err })
      return []
    })
})

const clasesCalendario = mergeClases(clasesList)
  .map(clasesDia=> ({
    fecha: clasesDia.fecha,
    clases: clasesDia.clases.map(clase=> (
      <ClaseCalendario
        key={clase.id}
        hora={clase.hora}
        profesor={clase.profesor}
        dimmed={!isSameMonth(dateFromString(clasesDia.fecha), mes)}
      />
    ))
  }))
  .reduce((acc, clasesDia)=> ({
      ...acc,
      [clasesDia.fecha]: clasesDia.clases
    }
  ), {})

  return (
    <div className={css.main}>
      <div className={css.header}>
        <TableHeader
          actions={(
            <AcademiaSelector
              name='academia'
              label='Academia'
              value={centro}
              onChange={e=> setCentro(e.target.value)}
            />
          )}
          title='Profesores disponibles'
          showSearch={false}
        />
      </div>
      <div className={css.calendar}>
        <div className={css.monthSelection}>
          <IconButton className={css.monthIcon} size='small' onClick={()=> handleMonthChange(-1)}>
            <i className='material-icons'>arrow_back</i>
          </IconButton>
          <p className={css.calendarMonth}>
            {dateToMonth(mes)}
          </p>
          <IconButton className={css.monthIcon} size='small' onClick={()=> handleMonthChange(+1)}>
            <i className='material-icons'>arrow_forward</i>
          </IconButton>
        </div>
        <Calendar
          month={monthToString(mes)}
          events={clasesCalendario}
          placeholder='Sin clases'
        />
      </div>
    </div>
  )
}

export default Profesores