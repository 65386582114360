import { useEffect, useState } from "react"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import { dateToString, dateToWeekday, getToday } from "../../utils/date"
import FechaSelector from "../../components/form/FechaSelector/FechaSelector"
import { getAsistenciaLeadList, getAsistenciaList, getAsistenciaTotals } from "../../utils/api/asistencias"
import { useQuery } from "@tanstack/react-query"
import { useNotification } from "../../context/NotificationManager/NotificationManager"
import { getHorarioCentro } from "../../utils/api/modelosHorario"
import PageLoading, { LoadingSpinner } from "../../components/PageLoading/PageLoading"
import Tabs from "../../components/Tabs/Tabs"
import { getTotales } from "../../utils/model/asistencias"
import { getMostRelevantHour } from "../../utils/model/horario"
import ClaseRow from "./components/ClaseRow/ClaseRow"
import PruebaRow from "./components/PruebaRow/PruebaRow"
import useResponsive from "../../hooks/useResponsive"
import css from './Planificacion.module.css'
import AcademiaSelector from "../../components/form/AcademiaSelector/AcademiaSelector"
import TableHeader from "../../components/TableHeader/TableHeader"

const TabContent = ({ 
  pruebas,
  asistencias, 
  centro,
  isLoading,
})=> {

  const [open, setOpen] = useState('')

  const { isScreenBiggerThan } = useResponsive()

  if (isLoading) return <LoadingSpinner />

  return (
    <TableContainer className={css.tabContent}>
      <Table stickyHeader className={css.table} size='small'>
        <TableHead>
        <TableRow>
          <TableCell className={css.cell}>
            Alumno
          </TableCell>
          <TableCell 
            className={css.cell} 
            data-visibility={isScreenBiggerThan('sm') ? 'visible' : 'invisible'}
          >
            Asignatura
          </TableCell>
          <TableCell 
            className={css.cell} 
            data-visibility={isScreenBiggerThan('md') ? 'visible' : 'invisible'}
          >
            Profesor
          </TableCell>
          <TableCell 
            className={css.cell} 
            data-visibility={isScreenBiggerThan('lg') ? 'visible' : 'invisible'}
          >
            Tipo de clase
          </TableCell>
          <TableCell className={css.cell} align="center">
            Asistencia
          </TableCell>
          <TableCell className={css.cell} />
        </TableRow>
        </TableHead>
        <TableBody>
          {pruebas
            .sort((p1, p2)=> p1.feedbackNombre.localeCompare(p2.feedbackNombre))
            .map(asistencia=> (
              <PruebaRow 
                key={asistencia.feedbackId}
                asistencia={asistencia}
                isOpen={open === asistencia.feedbackId}
                onOpen={()=> setOpen(open === asistencia.feedbackId ? '' : asistencia.feedbackId)}
              />
            ))}
          {asistencias
            .sort((a1, a2)=> a1.alumno.localeCompare(a2.alumno))
            .map(asistencia=> (
              <ClaseRow 
                key={asistencia.id}
                asistencia={asistencia}
                centro={centro}
                isOpen={open === asistencia.id}
                onOpen={() => setOpen(open === asistencia.id ? '' : asistencia.id)}
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const TabLabel = ({ hora, total })=> {
  if (!total) return hora
  const { alumnos, detalles } = getTotales({ total })
  return (
    <div className={css.tabLabel}>
      <div>{hora} ({alumnos})</div>
      <div className={css.tabLabelDetalles}>
        {detalles.map(detalle=> (
          <span className={css.tabLabelElement} data-tipo={detalle.type} key={detalle.type}>
            {detalle.value}
          </span>
        ))}
      </div>
    </div>
  )
}

const Planificacion = ()=> {

  const notification = useNotification()

  const [centro, setCentro] = useState('')
  const [fecha, setFecha] = useState(getToday())
  const [hora, setHora] = useState(null)

  const isCentroSelected = !!centro

  const { 
    isLoading: isHorarioLoading, 
    isError: isHorarioError, 
    isSuccess: isHorarioSuccess,
    data: horario=[] 
  } = useQuery({
    queryKey: ['planificacion', 'modelo-horario', dateToString(fecha), centro], 
    enabled: isCentroSelected,
    queryFn: ()=> getHorarioCentro({ fecha: dateToString(fecha), centro: centro })
      .then(datos=> datos.horas)
  })

  const { data: totals={} } = useQuery({
    queryKey: [ 'planificacion', 'asistencia', 'list', 'totals', dateToString(fecha), centro], 
    enabled: isCentroSelected && fecha !== null,
    queryFn: ()=> getAsistenciaTotals({ fecha: dateToString(fecha), centro })
      .then(datos=> datos)
      .catch(()=> ({}))
  })

  const { isFetching: isAsistenciasLoading, data: asistenciaList=[] } = useQuery({
    queryKey: [ 'planificacion', 'asistencia', 'list', dateToString(fecha), hora, centro], 
    enabled: isCentroSelected && fecha !== null && hora !== null,
    queryFn: ()=> getAsistenciaList({ fecha: dateToString(fecha), hora, centro })
      .then(datos=> datos)
      .catch(err=> {
        notification.error({ title: 'Error al recuperar las asistencias', content: err })
        return []
      })
  })

  const { isFetching: isPruebasLoading, data: pruebaList=[] } = useQuery({
    queryKey: [ 'planificacion', 'asistencia', 'feedback', 'list', dateToString(fecha), hora, centro],
    enabled: isCentroSelected && fecha !== null && hora !== null,
    queryFn: ()=> getAsistenciaLeadList({ fecha: dateToString(fecha), hora, centro })
      .then(datos=> datos)
      .catch(err=> {
        notification.error({ title: 'Error al recuperar las pruebas', content: err })
        return []
      })
  })

  const horaActual = getMostRelevantHour(horario)

  useEffect(()=> {
    if (!hora) setHora(horaActual)
  }, [hora, horaActual])

  useEffect(()=> {
    setHora(null)
  }, [fecha])


  return (
    <PageLoading isLoading={isHorarioLoading}>
      <div className={css.header}>
        <TableHeader
          actions={(
            <div className={css.actions}>
              <AcademiaSelector
                className={css.centroSelector}
                name='academia'
                label='Academia'
                value={centro}
                onChange={e=> setCentro(e.target.value)}
              />
              <FechaSelector
                className={css.fechaSelector}
                label='Cambiar fecha'
                value={null}
                onChange={setFecha}
              />
            </div>
          )}
          title={`Clases del día ${dateToString(fecha)} (${dateToWeekday(fecha)})`}
          showSearch={false}
        />
      </div>
      {!isCentroSelected && (
        <div className={css.error}>
          <p className={css.errorMessage}>
            Elige que academia quieres consultar
          </p>
        </div> 
      )}
      {isHorarioError && (
        <div className={css.error}>
          <p className={css.errorMessage}>
            No hay horario configurado para este día
          </p>
        </div> 
      )}
      {isHorarioSuccess && (
        <Tabs
          className={css.tabs}
          tabs={horario.map(hora=> ({
            name: hora,
            label: <TabLabel hora={hora} total={totals[hora]} />,
            content: (
              <TabContent 
                asistencias={asistenciaList}
                pruebas={pruebaList}
                centro={centro}
                isLoading={isAsistenciasLoading && isPruebasLoading}  
              />
            ),
          }))}
          activeTab={hora}
          onChange={setHora}
        />
      )}
    </PageLoading>
  )


}

export default Planificacion